import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Form, NavLink } from 'react-bootstrap';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { dataValue } from "../../../CommonReducer";
import Index from "../../../Assets/Images/Index";

import * as Icon from 'react-bootstrap-icons';
import * as Api from "../../../Config/Apis"
import endPoints from "../../../Config/endPoints";

function Login() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [showAlert, setshowAlert] = useState(false)
    const [show, setShow] = useState(false)
    const [value, setValue] = useState({
        email: "",
        password: ""
    })

    const [password, setpassword] = useState(false)
    const toggleShowPassword = () => {
        setpassword(!password)
    }

    const handlechange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
        if (e.target.name == "password") {
            if (e.target.value !== "") {
                setShowPassword(true)
            } else {
                setShowPassword(false)
            }
        }
    }
    function handleSubmit(e) {
        e.preventDefault();

        let data = { ...value }
        loginFn(data)
    }
    const loginFn = (data) => {
        Api.PostApi(endPoints.LOGIN, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            }
            else {
                if (res.data.data) {
                    let resData = res.data.data
                    Api.setToken(resData?.token)
                    navigate('/dashboard')
                    dispatch(dataValue({ 'login': true }))
                    localStorage.setItem("name", resData?.first_name);
                    localStorage.setItem("lname", resData?.last_name);
                }
            }
        })
    }

    useEffect(() => {
        if (location.pathname == "/") {
            const to = { pathname: '/login' };
            navigate(to, { replace: true });
        }
    }, [])


    return (
        <div className="loginPage">
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="loginPageContainer justify-content-center">
                        <div className="d-flex justify-content-center py-4">
                            <div className="logo d-flex align-items-center w-auto">
                                <img src={Index.logo} alt="" />
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="pb-2">
                                    <h5 className="card-title text-center fs-4">Login to your account</h5>
                                </div>
                                <form className="row g-3 needs-validation" onSubmit={handleSubmit}>
                                    <div className="col-12">
                                        <label htmlFor="yourUsername" className="form-label">Username</label>
                                        <div className="input-group has-validation">
                                            <input type="text" value={value.name} name="email" onChange={handlechange} className="form-control" id="yourUsername" required="" />
                                            <div className="invalid-feedback">Please enter your username.</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="yourPassword" className="form-label">Password</label>
                                        <div className='passwordField'>
                                            <input
                                                type={password ? "text" : "password"}
                                                name="password"
                                                onChange={handlechange}
                                                className="form-control"
                                                id="yourPassword"
                                                required="" />
                                            <button type="button" className='passwordField__btn' onClick={toggleShowPassword}>
                                                {password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                            </button>
                                        </div>

                                        <div className="invalid-feedback">Please enter your password!</div>
                                    </div>

                                    <div className="col-12">
                                        <button className={`btn btn-primary w-100 ${value.email === "" || value.password === "" ? "disabled" : null}`} onClick={handleSubmit} type="submit">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
export default Login;