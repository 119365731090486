import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore"
import StateName from '../../../../Config/StateName';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import ResponsivePagination from 'react-responsive-pagination';
import InfiniteScroll from "react-infinite-scroll-component";


// SESSION

export default function CoPartners() {
    const scrollRef = useRef();

    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [updateId, setupdateId] = useState("")
    const [activeId, setactiveId] = useState({})

    const [modal, setmodal] = useState({
        size: "lg",
        type: ""
    })
    const [loadingBtn, setloadingBtn] = useState(false);
    const [show, setShow] = useState(false);
    let limit = 20
    const [params, setParams] = useState({
        // sort_key: searchParams.get("sort_key") || "",
        // sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
    })
    const [total, setTotal] = useState(0)
    const [count, setCount] = useState(1)

    const usertype = [
        { label: "Business", value: "business" },
        { label: "Individual", value: "individual" }
    ]
    let valueInt = {
        type: "business",
        name: "",
        email: "",
        phone_number: "",
        address: {
            "country": "Malaysia",
            "city": "",
            "postalCode": "",
            "state": ""
        }
    }
    const [value, setValue] = useState(valueInt)
    const [checkedCharger, setcheckedCharger] = useState([])
    const [activeTab, setactiveTab] = useState("Assigned")

    const [chrgerTab, setchrgerTab] = useState([
        { name: "Assigned", active: true },
        { name: "Available", active: false },
    ])

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const handleClose = () => {
        setShow(false)
        setcheckedCharger([])
        setValue(valueInt)
        setmodal({})
        setactiveId({})
        setunAssignChargingStaions([])
        setloadingBtn(false)
        seteditPhone_number("")
        setchargerList([]);
        sethasMore(true);
        setchargerPage(1);
    };
    const handleShow = () => {
    }
    const [hasMore, sethasMore] = useState(true)
    const [chargerPage, setchargerPage] = useState(1)
    const [totalCharger, settotalCharger] = useState(0)

    const [chargerList, setchargerList] = useState([])
    const [partnerList, setpartnerList] = useState([])
    const [currentpartnerId, setcurrentpartnerId] = useState("")
    const [availChargerSrch, setavailChargerSrch] = useState("")



    const setSearchCharget = (parameter) => {
        Api.GetApi(`${endPoints.UNASSIGN_CHARGER_LIST}/${parameter}`).then(res => {
            setloader(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                // console.log("red", res.data.data)

                if (res.data.data.length > 0) {
                    if (res.data.data[0].result && res.data.data[0].result.length > 0) {
                        const newData = res.data.data[0].result
                        if (chargerPage > 1) {
                            setchargerList(prevData => [...prevData, ...newData])
                        } else {
                            setchargerList(res.data.data[0].result)
                        }

                        settotalCharger(res.data.data[0].totalCount?.count)


                        if (newData.length === 0) {
                            sethasMore(false);
                        }

                    }
                } else {
                    setchargerList(res.data.data)
                }
            }
        })
    }
    console.log("chargerPage", chargerPage)
    // console.log("totalCharger", totalCharger)
    // console.log("chargerList.length", chargerList.length)

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // console.log("availChargerSrch:", availChargerSrch);
            if (availChargerSrch !== "") {
                setchargerPage(1)
                if (currentpartnerId !== "") {
                    setSearchCharget(`?partner_id=${currentpartnerId}&search=${availChargerSrch}&page=${chargerPage}`)
                } else {
                    setSearchCharget(`?search=${availChargerSrch}&page=${chargerPage}`)
                }

            } else {

                if (currentpartnerId !== "") {

                    setSearchCharget(`?partner_id=${currentpartnerId}&page=1`)
                } else {
                    setSearchCharget("?page=1")

                }



            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [availChargerSrch]);

    const getpartnerList = (type, parameter) => {
        console.log(type, "type")
        console.log("type", type)
        Api.GetApi(`${endPoints.PARTNER_LIST}${parameter}`).then(res => {
            setsearchProgress(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {

                if (res.data.data.length > 0 && res.data.data[0].result && res.data.data[0].result.length > 0) {

                    if (type == "update") {
                        let active = res.data.data[0].result.filter((x) => x?._id == activeId?._id)
                        if (active.length > 0) {
                            setactiveId(active[0])
                        }
                    }
                }
                setpartnerList(res?.data?.data[0]?.result)

                if (res.data.data.length > 0) {
                    setTotal(res.data.data[0]?.totalCount?.count)
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                } else {
                    setCount(1)
                }
                setloading(false)
            }
        })
    }

    const [searchProgress, setsearchProgress] = useState(false)
    const [search, setsearch] = useState(searchParams.get("search") || "")
    const searchUser = (e) => {
        setsearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("page", 1)
            setparam("search", e.target.value)
        }
    }
    useEffect(() => {
        // deleteparam("page")
        const delayDebounceFn = setTimeout(() => {
            if (search != "") {
                setsearchProgress(true)
                setParams({ ...params, page: 1, search: search })
                setparam('search', search)
            }

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        setsearch("")
        deleteparam("search")
        setsearchProgress(true)
    }

    const savePartner = (data) => {
        Api.PostApi(endPoints.ADD_PARTNER, data).then(res => {
            // console.log("save", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloadingBtn(false)
            } else {
                setloadingBtn(false)
                handleClose()
                // setSearchCharget("") 
                getpartnerList("", location.search)
                if (res.data.data?.testkeys) {
                    if (Api.env == "dev") {
                        Api.Alert("Partner created successfully", "success")
                        setTimeout(() => {
                            window.open(res.data.data?.testkeys, "_blank")
                        }, 2000);
                    }
                    else {
                        Api.Alert(res.data.data?.messages, "success")
                    }
                }
            }
        })
    }
    const updatePartner = (data) => {
        Api.PutApi(endPoints.UPDATE_PARTNER, data).then(res => {
            //  console.log("update", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloadingBtn(false)
            } else {
                setloadingBtn(false)
                Api.Alert("Partner updated successfully", "success")
                handleClose()
                // setSearchCharget("") 
                getpartnerList("", location.search)
                seteditPhone_number("")
                // if (res.data.data) {
                //     if (Api.env == "dev") {
                //         setTimeout(() => {
                //             window.open(res.data.data, "_blank")
                //         }, 2000);
                //     }
                // }
            }
        })
    }

    const confirmDeletePartner = (id) => {
        Api.DeleteApi(`${endPoints.CO_PARTNER}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                Api.Alert(res.data.data, "success")
                handleClose()
                getpartnerList("", location.search)
                // setSearchCharget("")
            }
        })
    }
    const unassignFn = (data) => {
        Api.PutApi(endPoints.UNASSIGN_CHARGER, data).then(res => {
            // console.log("unassign", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                Api.Alert(res.data.data, "success")
                cancelUnassign()
                // handleClose() 
                getpartnerList("update", location.search)
                setSearchCharget(`?partner_id=${currentpartnerId}`)
                console.log("data -res --", res)
            }
        })
    }

    const addNew = () => {
        setSearchCharget("?page=1")
        setShow(true)
        setmodal({ type: "add", size: "lg" })
    }
    const viewCharger = (data) => {
        setactiveId(data)
        setShow(true)
        setmodal({ type: "view", size: "lg" })
    }
    const [editPhone_number, seteditPhone_number] = useState("")
    const editPartner = (data) => {
        setactiveId(data)
        setcurrentpartnerId(data._id)
        setSearchCharget(`?partner_id=${data._id}`)
        if (data?.country_code) {
            seteditPhone_number(`+${data?.country_code} ${data?.phone_number}`)
        } else {
            seteditPhone_number(data?.phone_number)
        }
        setValue({
            ...value,
            type: data?.type,
            name: data?.name,
            email: data?.email,
            phone_number: data?.phone_number,
            country_code: data?.country_code,
            address: {
                city: data?.address[0]?.city,
                state: data?.address[0]?.state,
                postalCode: data?.address[0]?.postalCode,
            }
        })
        getpartnerList("", location.search)

        setShow(true)
        setmodal({ type: "edit", size: "lg" })
    }
    const deletePartner = (data) => {
        setupdateId(data)
        setShow(true)
        setmodal({ type: "delete", size: "md" })
    }

    const confirmClick = () => {
        // console.log("updateId: ", updateId)
        confirmDeletePartner(updateId)
    }

    const handleInputchange = (e, type) => {
        if (type == "") {
            setValue({ ...value, [e.target.name]: e.target.value })
        } else {
            setValue({
                ...value,
                [type]: {
                    ...value[type],
                    [e.target.name]: e.target.value
                }
            })
        }
    }
    const handleSelect = (e) => {
        setValue({
            ...value,
            "address": {
                ...value["address"],
                [e.target.name]: e.target.value
            }
        })
    }
    const handleCheck = (e) => {
        if (e.target.checked) {
            let arr = [...checkedCharger]
            arr.push(e.target.value)
            setcheckedCharger(arr)
        } else {
            let arr = checkedCharger.filter((x) => x !== e.target.value)
            setcheckedCharger(arr)
        }
    }
    const [unAssignChargingStaions, setunAssignChargingStaions] = useState([])
    const handleUnCheck = (e) => {
        if (e.target.checked) {
            let arr = activeId.assigncharger.filter((x) => x?.chargeStation_id !== e.target.value)
            setunAssignChargingStaions(arr)
        } else {
            let arr = [...unAssignChargingStaions]
            arr.push(e.target.value)
            setunAssignChargingStaions(_.uniq(arr))
        }
    }
    const radioNameChange = (e) => {
        setValue({ ...value, type: e.target.value })
    }
    //console.log("unAssignChargingStaions: ", unAssignChargingStaions)
    const unassign = (data) => {
        let arr = []
        activeId.assigncharger.map((opt) => {
            if (opt?.chargeStation_id == data?.chargeStation_id) {
                arr.push({ ...opt, unassign: true })
            } else {
                delete opt["unassign"]
                arr.push({ ...opt })
            }
            setactiveId({ ...activeId, assigncharger: arr })
        })
    }
    const confirmUnassign = (opt, lnth) => {
        if (opt?.partner_id && opt?.chargeStation_id) {
            let data = {
                "partner_id": opt?.partner_id,
                "chargingStation": opt?.chargeStation_id
            }
            unassignFn(data)
            if (lnth === 1) {
                setTimeout(() => {
                    handleClose()
                }, 2000);
            }
        } else {
            cancelUnassign()

        }
    }
    const cancelUnassign = () => {
        let arr = []
        activeId.assigncharger.map((opt) => {
            delete opt["unassign"]
            arr.push({ ...opt })
            setactiveId({ ...activeId, assigncharger: arr })
        })
    }

    const [errors, setErrors] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPhone, setInvalidPhone] = useState(false)
    const submitForm = (e) => {
        e.preventDefault();
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value.name == "" || value.email == "" || value.phone_number == "" || value.address.city == "" || value.address.state == "" || value.address.postalCode == "") {
            setErrors(true)
            setInvalidEmail(false)
        } else if (value.email !== "" && !re.test(value.email)) {
            setErrors(true)
            setInvalidEmail(true)
        } else if (value?.phone_number === "" || value?.phone_number.length < 9 || document.querySelectorAll(".form-control.invalid-number").length > 0) {
            setErrors(true)
            setInvalidPhone(true)
        }
        else {
            setloadingBtn(true)
            setInvalidEmail(false)
            setInvalidPhone(false)
            setErrors(false)

            let data = {}
            if (modal.type == "edit") {
                data = {
                    ...value,
                    partner_id: activeId?._id,
                    assignChargingStations: checkedCharger,
                    unAssignChargingStaions: unAssignChargingStaions
                }
                updatePartner(data)
            } else {
                data = {
                    ...value,
                    chargingStation: checkedCharger
                }
                // console.log("data: ", data)
                savePartner(data)
            }
        }
    }

    const clickTab = (e) => {
        setavailChargerSrch("")
        setactiveTab(e.target.name)
    }

    const handlePhonechange = (num, data) => {
        // console.log(num.replace(/[^0-9]+/g, "").slice(data.dialCode.length))
        seteditPhone_number(`+${data.dialCode} ${num.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`)
        setValue({
            ...value,
            //  'phone_number': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`,

            'phone_number': num.replace(/[^0-9]+/g, "").slice(data.dialCode.length),
            'country_code': data.dialCode
        })
    }
    const resendActivationLink = (id) => {
        // RESEND_VERIFICATION_LINK
        Api.GetApi(`${endPoints.RESEND_VERIFICATION_LINK}/${id}`).then(res => {
            // console.log("unassign", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                Api.Alert(res.data.data.messages, "success")
                getpartnerList("update", location.search)
            }
        })

    }

    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }
    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])

    useEffect(() => {

        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });

        getpartnerList("", location.search)
    }, [params])

    const [loader, setloader] = useState(true)

    console.log("[location.search]", location.search)
    console.log("[location.search]", params)

    const searchCharger = (e) => {
        // console.log("e", e.target.value)
        setloader(true)
        setchargerList([]);
        sethasMore(true);
        setchargerPage(1);
        setavailChargerSrch(e.target.value)
    }

    // console.log("chargerList", chargerList.length)
    // console.log("chargerList - totalCharger ", totalCharger)
    // console.log("hasMore ", hasMore)
    const loadMore = () => {
        console.log("load more")
        if (chargerList.length >= totalCharger) {
            sethasMore(false);
            return;
        } else {
            sethasMore(true);
            setTimeout(() => {
                setchargerPage(prevPage => prevPage + 1);
            }, 1000);
        }
    };

    useEffect(() => {
        if (currentpartnerId !== "") {
            setSearchCharget(`?partner_id=${currentpartnerId}&page=${chargerPage}`);
            // setSearchCharget(`?partner_id=${currentpartnerId}
        } else {
            setSearchCharget(`?page=${chargerPage}`);

        }
        // }, [chargerPage, availChargerSrch]);
    }, [chargerPage]);

    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle d-flex justify-content-between align-items-center">
                        <h1>Co-Partners Management</h1>
                        <button className='btn btn-danger' onClick={addNew}>Add Co-Partner</button>
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className='userdetailTopRow__left col-12 col-md-5'>
                                <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row  " >
                                    <div className="search-bar pe-1  ">
                                        <div className="search-form d-flex align-items-center" >
                                            <input type="search" className='form-control'
                                                name="search" placeholder="Search by name/email/phone" value={search} title="Enter search keyword" onChange={searchUser} autoComplete="off" disabled={searchProgress} autocomplete="off" />
                                            <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                        </div>
                                    </div>
                                    {search !== "" ?
                                        <button type="button" disabled={searchProgress} className="textClear nowrap " style={{ opacity: searchProgress ? "0" : "1" }} onClick={clearSearch}>Clear Search</button> : null
                                    }
                                </div>
                            </div>
                            {partnerList && partnerList.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable copartnerTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Address</th>
                                                    <th scope="col">Chargers</th>
                                                    <th scope="col" width="250"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partnerList.length > 0 && partnerList.map((data, index) => {
                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index} >
                                                            <td>{(params.page - 1) * 20 + updInd}</td>
                                                            <td>{data?.name || ""}</td>
                                                            <td>{data?.email || ""}</td>
                                                            <td>{data?.country_code ? `${data?.country_code.includes("+") ? null : "+"}${data?.country_code}` : null} {data?.phone_number || ""}</td>
                                                            <td>
                                                                {data?.address.length > 0 ?
                                                                    <>
                                                                        {data.address.map((addr, i) => {
                                                                            return (
                                                                                <p key={i}>{addr?.city}{" "}{addr?.state ? `, ${addr?.state}` : ""}{" "}{addr?.postalCode ? `, ${addr?.postalCode}` : ""}</p>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    : ""
                                                                }
                                                            </td>
                                                            <td>
                                                                <strong className='me-3'>{data?.assigncharger.length}</strong>
                                                                {data?.assigncharger.length > 0 &&
                                                                    <button className='btn btn-outline-primary btn-sm' onClick={() => viewCharger(data)} title='View'>View</button>
                                                                }
                                                            </td>
                                                            <td style={{ textAlign: "right" }}>
                                                                {data?.verify ? null :
                                                                    <button onClick={() => resendActivationLink(data?._id)} className='btn btn-success btn-sm me-2' title='Resend Activation Link' >
                                                                        <Icon.ArrowClockwise /> Resend Link</button>
                                                                }

                                                                <button className='btn btn-success btn-sm me-2' onClick={() => editPartner(data)} title='Edit partner'><Icon.PencilFill /></button>
                                                                <button className='btn btn-danger btn-sm' onClick={() => deletePartner(data?._id)} title='Delete partner'><Icon.TrashFill /></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>


                                </> :
                                <div className='text-center py-5'>
                                    <h3>No result found</h3>
                                </div>
                            }

                            <div className='d-flex align-items-center'>
                                {/* Showing {partnerList.length} of {total} */}
                                <div style={{ maxWidth: "500px" }}>
                                    <ResponsivePagination
                                        current={parseFloat(searchParams.get("page"))}
                                        total={count.length}
                                        onPageChange={setCurrentPage}
                                        disabled={params.page === count.length}
                                        // maxWidth={500}
                                        className={"pagination "}
                                    />

                                </div>


                            </div>
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose} centered size={modal.size}>
                        <Modal.Body className='py-4'>
                            {modal.type == "view" ?
                                <>
                                    {!_.isEmpty(activeId) && activeId?.assigncharger && activeId?.assigncharger.length > 0 ?
                                        <>
                                            {activeId?.assigncharger.map((opt, i) => {
                                                return (
                                                    <div className="card" key={i}>
                                                        <div className="card-header d-flex justify-content-between align-items-center" style={{ backgroundColor: "#e7e7e7" }}>
                                                            <div className=''>
                                                                <strong>{opt?.chargeingstaion?.endpoint}</strong>
                                                                {opt?.chargeingstaion?.public ?
                                                                    <span className="ms-3 badge bg-primary">Public</span>
                                                                    : <span className="ms-3 badge bg-primary">Private</span>
                                                                }
                                                            </div>
                                                            {opt?.unassign ?
                                                                <div>
                                                                    <button className='btn btn-success me-2 btn-sm' onClick={() => confirmUnassign(opt, activeId?.assigncharger.length)}>Confirm</button>
                                                                    <button className='btn btn-outline-danger btn-sm' onClick={cancelUnassign} >Cancel</button>
                                                                </div>
                                                                :
                                                                <button className='btn btn-danger btn-sm' onClick={() => unassign(opt)} > Unassign</button>
                                                            }
                                                        </div>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                                                <strong>ID: </strong>
                                                                <span>{opt?.chargeStation_id}</span>
                                                            </li>
                                                            <li className="list-group-item">
                                                                <strong>Static Endpoint: </strong>
                                                                <span>{opt?.chargeingstaion?.static_endpoint}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <h5 className='py-5 text-center'>No charger found!</h5>
                                    }
                                </>
                                : null
                            }
                            {modal.type == "delete" ?
                                <>
                                    <div className='text-center mb-4'>
                                        <h4> Are you sure?</h4>
                                        <h5>You want to delete this partner</h5>
                                    </div>

                                    <div className='text-center mt-2'>
                                        <button className='btn btn-danger btn-sm me-3' onClick={confirmClick}> Confirm</button>
                                        <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                                    </div>
                                </>
                                : null
                            }

                            {modal.type == "add" || modal.type == "edit" ?
                                <>
                                    <h5>{modal.type == "add" ? "Add New" : "Edit"} Co-Partner</h5>
                                    <div className="row mt-3">
                                        <div className="col-lg-7  mb-2">
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <Form.Group >
                                                        <Form.Label className="d-block "><strong>Business or individual*</strong></Form.Label>
                                                        {usertype.map((data, indx) => {
                                                            return (
                                                                <Form.Check
                                                                    key={indx}
                                                                    type="radio"
                                                                    name='businessName'
                                                                    label={data.label}
                                                                    id={`nameType-${indx}`}
                                                                    value={data.value}
                                                                    checked={data.value == value.type}
                                                                    onChange={radioNameChange}
                                                                    inline
                                                                />
                                                            )
                                                        })}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Group >
                                                        <Form.Label><strong>Name*</strong></Form.Label>
                                                        <Form.Control type="text" name="name" placeholder=""
                                                            onChange={(e) => handleInputchange(e, "")}
                                                            value={value?.name}
                                                            isInvalid={errors && value?.name == ""}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-12">
                                                    <Form.Group >
                                                        <Form.Label><strong>Email address*</strong></Form.Label>
                                                        <Form.Control type="email" name="email" placeholder="" onChange={(e) => handleInputchange(e, "")} value={value?.email} isInvalid={errors && (value?.email == "" || (value?.email !== "" && invalidEmail))} />
                                                        {errors && !invalidEmail ?
                                                            <Form.Control.Feedback type="invalid">Please enter email</Form.Control.Feedback>
                                                            : null
                                                        }
                                                        {errors && invalidEmail ?
                                                            <Form.Control.Feedback type="invalid">Wrong email format</Form.Control.Feedback>
                                                            : null
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-12">
                                                    <Form.Group >
                                                        <Form.Label><strong>Phone number*</strong></Form.Label>
                                                        {/* <CurrencyFormat
                                                            name='phone_number'
                                                            onChange={(e) => handleInputchange(e, "")}
                                                            value={value?.phone_number} 
                                                            className={`form-control ${errors && value?.phone_number == "" ? "is-invalid" : ""}`}
                                                            format="##########" /> */}
                                                    </Form.Group>
                                                    <PhoneInput
                                                        className={invalidPhone || value?.phone_number == "" || value?.phone_number.length < 9 ? "is-invalid" : ""}
                                                        enableSearch={true}
                                                        disableAreaCodes={false}
                                                        disableSearchIcon={true}
                                                        country={"my"}
                                                        value={editPhone_number?.toString()}
                                                        inputExtrastate={{ name: 'mobile', required: true }}
                                                        inputProps={{
                                                            minLength: '13',
                                                            maxLength: '21'
                                                        }}
                                                        onChange={(e, x) => handlePhonechange(e, x)}
                                                        isValid={(value, data) => {
                                                            if (
                                                                value
                                                                    .toString().replace(/[^0-9]+/g, "")
                                                                    .slice(data && (data.dialCode.length)).length < 7 &&
                                                                value
                                                                    .toString().replace(/[^0-9]+/g, "")
                                                                    .slice(data && (data.dialCode.length)).length > 0
                                                            ) {
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }}
                                                        placeholder=""
                                                        countryCodeEditable={false}
                                                        defaultMask=".. .... .... ...."
                                                    />

                                                    {errors && value?.phone_number == "" ?
                                                        <div className='invalid-feedback'>Please enter phone number</div>
                                                        : null}

                                                    {errors && invalidPhone && value?.phone_number != "" ?
                                                        <div className='invalid-feedback'>Enter valid phone number</div>
                                                        : null
                                                    }

                                                </div>
                                                <div className="col-12">
                                                    <Form.Group >
                                                        <Form.Label><strong>State*</strong></Form.Label>
                                                        {/* <Form.Control type="text" name="state" placeholder="" onChange={(e) => handleInputchange(e, "address")} value={value?.address?.state} /> */}
                                                        <Form.Select onChange={handleSelect} name='state' value={value.address.state}>
                                                            <option value={""} defaultValue disabled>Select state</option>
                                                            {Object.keys(StateName).map((key) => {
                                                                return (
                                                                    <option value={key} key={key}>{key}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                        {errors && value?.address.state == "" ?
                                                            <div className='invalid-feedback d-block'>Please select state name</div>
                                                            : null}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-8">
                                                    <Form.Group >
                                                        <Form.Label><strong>City*</strong></Form.Label>
                                                        {/* <Form.Control type="text" name="city" placeholder="" onChange={(e) => handleInputchange(e, "address")} value={value?.address?.city} /> */}
                                                        <Form.Select onChange={handleSelect} name='city' disabled={value?.address?.state == ""} value={value.address.city}>
                                                            <option value={""} defaultValue disabled>Select city</option>
                                                            {!_.isEmpty(value) && value?.address?.state !== "" && _.contains(Object.keys(StateName), value?.address?.state) && StateName[value?.address?.state].map((key) => {
                                                                return (
                                                                    <option value={key} key={key} >{key}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                        {errors && value?.address.city == "" ?
                                                            <div className='invalid-feedback  d-block'>Please select city name</div>
                                                            : null}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-4">
                                                    <Form.Group >
                                                        <Form.Label><strong>Postal code*</strong></Form.Label>
                                                        <CurrencyFormat name='postalCode' onChange={(e) => handleInputchange(e, "address")} value={value?.address?.postalCode} className="form-control" format="#####" />
                                                        {errors && value?.address.postalCode == "" ?
                                                            <div className='invalid-feedback  d-block'>Enter postal code</div>
                                                            : null}
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-5 mb-2">
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <Form.Group >
                                                        <div className='chargers_assignment'>
                                                            {modal.type == "edit" ?
                                                                <>
                                                                    <ul className="nav nav-tabs nav-tabs-bordered mb-3" id="borderedTab" role="tablist">
                                                                        {chrgerTab.map((data, index) => {
                                                                            return (
                                                                                <li key={index} className="nav-item" role="presentation">
                                                                                    <button name={data.name} className={activeTab === data.name ? "nav-link active" : "nav-link"} onClick={(e) => clickTab(e)} type="button" role="tab" aria-controls="home"  >{data.name} chargers</button>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                    {activeTab == "Assigned" ?
                                                                        activeId?.assigncharger?.length > 0 ?
                                                                            activeId?.assigncharger.map((opt, i) => {
                                                                                return (
                                                                                    <Form.Check
                                                                                        key={i}
                                                                                        type={"checkbox"}
                                                                                        id={opt?.chargeStation_id}
                                                                                        label={opt?.chargeingstaion?.endpoint || ""}
                                                                                        value={opt?.chargeStation_id}
                                                                                        onChange={handleUnCheck}
                                                                                        checked={unAssignChargingStaions.filter((x) => x == opt?.chargeStation_id).length == 0}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : <h5>No charger assigned</h5>
                                                                        :
                                                                        <>
                                                                            <div className="search-bar  mb-3 mb-md-0  userdetailTopRow__left">
                                                                                <div className="search-form d-flex align-items-center mb-3" >
                                                                                    <input type="text" className='form-control' name="search" placeholder="Search"
                                                                                        // value={params.search} 
                                                                                        title="Enter search keyword"
                                                                                        onChange={(e) => searchCharger(e)}
                                                                                    />
                                                                                    <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                                                                </div>
                                                                            </div>

                                                                            {/* <Form.Label><strong>Available chargers</strong></Form.Label> */}
                                                                            {loader ? "Loading..." : chargerList.length > 0 ?
                                                                                <div className='chargers_assignmentOverFlow' style={{ maxHeight: "340px" }}>
                                                                                    {/* {chargerList.map((opt, i) => {
                                                                                        return (
                                                                                            <Form.Check
                                                                                                key={opt?._id}
                                                                                                type={"checkbox"}
                                                                                                id={opt?._id}
                                                                                                label={opt?.endpoint || ""}
                                                                                                value={opt?._id}
                                                                                                onChange={handleCheck}
                                                                                            />
                                                                                        )
                                                                                    })} */}

                                                                                    <InfiniteScroll
                                                                                        dataLength={chargerList.length}
                                                                                        next={loadMore}
                                                                                        hasMore={hasMore}
                                                                                        loader={<p><strong>Loading...</strong></p>}
                                                                                        height={340}
                                                                                    >
                                                                                        {chargerList.map((opt, i) => {
                                                                                            return (
                                                                                                <Form.Check
                                                                                                    key={opt?._id}
                                                                                                    type={"checkbox"}
                                                                                                    id={opt?._id}
                                                                                                    label={opt?.endpoint || ""}
                                                                                                    value={opt?._id}
                                                                                                    onChange={handleCheck}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </InfiniteScroll>
                                                                                </div>
                                                                                :
                                                                                <h5>No charger available</h5>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Label><strong>Available chargers</strong></Form.Label>
                                                                    <div className="search-bar  mb-3 mb-md-0  userdetailTopRow__left">
                                                                        <div className="search-form d-flex align-items-center mb-3" >
                                                                            <input type="text" className='form-control' name="search" placeholder="Search"
                                                                                // value={params.search} 
                                                                                title="Enter search keyword"
                                                                                onChange={(e) => searchCharger(e)}
                                                                            />

                                                                            <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                                                        </div>
                                                                    </div>
                                                                    {loader ? "Loading..." : chargerList.length > 0 ?
                                                                        <div className='chargers_assignmentOverFlow'>
                                                                            <InfiniteScroll
                                                                                dataLength={chargerList.length}
                                                                                next={loadMore}
                                                                                hasMore={hasMore}
                                                                                loader={<p><strong>Loading...</strong></p>}
                                                                                height={340}
                                                                            >
                                                                                {chargerList.map((opt, i) => {
                                                                                    return (
                                                                                        <Form.Check
                                                                                            key={opt?._id}
                                                                                            type={"checkbox"}
                                                                                            id={opt?._id}
                                                                                            label={opt?.endpoint || ""}
                                                                                            value={opt?._id}
                                                                                            onChange={handleCheck}
                                                                                        />
                                                                                    )
                                                                                })}
                                                                            </InfiniteScroll>
                                                                        </div>
                                                                        :
                                                                        <h5>No charger available</h5>
                                                                    }
                                                                </>
                                                            }

                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center1 mt-4">
                                            {modal.type == "edit" ?
                                                <button className={`btn btn-primary ${loadingBtn ? "disabled" : ""}`} onClick={submitForm}>{loadingBtn ? "Please wait" : "Update"}</button>
                                                :
                                                <button className={`btn btn-primary ${loadingBtn ? "disabled" : ""}`} onClick={submitForm}>{loadingBtn ? "Please wait" : "Submit"}</button>
                                            }
                                            <button className="btn btn-outline-danger ms-2" onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                        </Modal.Body>
                        {/* <Modal.Footer>

                  
                </Modal.Footer> */}
                    </Modal>
                </main >}
        </>
    )
}
