import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// SESSION
import { energyfunction } from '../../../../Config/energyFunction';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';


export default function UnpaidSessions() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [activeSession, setActiveSession] = useState({})
    const [sessionCloseId, setsessionCloseId] = useState()
    const [show, setShow] = useState(false);
    let limit = 20
    const param = useParams()
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
    })
    const [count, setCount] = useState(1)

    const [modelType, setmodelType] = useState("")

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const handleClose = () => {
        setShow(false)
        setmodelType("")
    };
    const handleShow = () => setShow(true);

    const getactiveSession = (param) => {
        Api.GetApi(`${endPoints.UNPAIDSESSION}/${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                // console.log("res.data.", res.data.data)

                let arr = []
                res.data.data.length > 0 && res.data.data[0].result.length > 0 && res.data.data[0].result.map((opt) => {
                    var a = moment(opt?.metrics?.chargingStart);
                    var b = moment(opt?.metrics?.chargingStop);
                    var duration = moment.duration(b.diff(a));
                    duration.hours()
                    duration.minutes()
                    duration.seconds()
                    let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                    ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""}       ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`

                    arr.push({ ...opt, duration: duration1 })
                })
                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }

                setActiveSession(arr)
                setloading(false)
            }
        })
    }

    const updateBlockStat = (data) => {
        // STOP_SESSION
        // console.log(data)
        setsessionCloseId(data)
        setmodelType("getPaid")
        handleShow()
    }


    const confirmClose = (e) => {
        stopSessionFun(e.target.name)
    }


    const stopSessionFun = (id) => {

        Api.PutApi(`${endPoints.MARKPAIDSESSION}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                handleClose()
                Api.Alert("Marked as paid", "success")
                setloading(true)
                setTimeout(() => {
                    getactiveSession(location.search)
                }, 2000);
            }
        })

    }


    const openCancelPopup = (data) => {
        setsessionCloseId(data)
        setmodelType("cancelSession")
        handleShow()
    }

    const confirmCancel = (e) => {
        markascancelSession(e.target.name)
    }


    const markascancelSession = (id) => {
        console.log("s", id)

        Api.PostApi(`${endPoints.MARKCANCELSESION}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                handleClose()
                Api.Alert("The session was marked as canceled.", "success")
                setloading(true)
                setTimeout(() => {
                    getactiveSession(location.search)
                }, 2000);
            }
        })
    }


    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])


    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }

    useEffect(() => {
        getactiveSession(location.search)
    }, [location.search])
    console.log("activeSession", activeSession)
    console.log("page : ", params.page)
    console.log("ss : ", searchParams.get("page"))
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Unpaid Sessions</h1>
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            {activeSession && activeSession.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th className='sorting' scope="col">Session Id</th>
                                                    <th  >User</th>
                                                    <th scope="col">Start Time</th>

                                                    <th scope="col">Consumption(kWh)</th>
                                                    <th scope="col">Cost<small>(MYR)</small> </th>
                                                    <th scope="col">Payment Status</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeSession.length > 0 && activeSession.map((data, index) => {
                                                    let updInd = index + 1

                                                    return (
                                                        <tr key={index} >
                                                            <td>{(params.page - 1) * 20 + updInd}</td>
                                                            <td>{data?._id}</td>
                                                            <td className='fixedWidth'>
                                                                <Link to={`/users/${data?.user_id}`}>
                                                                    <strong>{data?.user?.first_name ? `${data?.user?.first_name} ${data?.user?.last_name}` : ""}</strong>
                                                                </Link>
                                                                <p className='mb-0'>{data?.user?.email || ""}</p>
                                                                <p>{data?.user?.verification?.country_code ? `${data?.user?.verification?.country_code.includes("+") ? "" : "+"}${data?.user?.verification?.country_code}` : ""} {data?.user?.phone_number || ""}</p>
                                                            </td>
                                                            {/* <td>{moment(data?.metrics.chargingStart).format("lll")}</td> */}
                                                            <td>{formatDate(data?.metrics.chargingStart)}</td>
                                                            <td>{energyfunction(data?.metrics?.energyConsumed)} </td>
                                                            <td><CurrencyFormat value={parseFloat(data?.cost?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></td>
                                                            <td><span className={`badge ${data?.payment ? "bg-success" : "bg-danger"}`}>{data?.payment ? "Paid" : "Unpaid"}</span></td>
                                                            <td>{data?.status} </td>
                                                            <td>

                                                                <button className='btn btn-success btn-sm' onClick={() => updateBlockStat(data?._id)}>Mark as Paid</button>
                                                                <button className='btn btn-danger btn-sm ms-2' onClick={() => openCancelPopup(data?._id)} >Cancel </button>

                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />

                                    </div>

                                </> :
                                <div className='text-center py-5'>
                                    <h3>No unpaid session</h3>
                                </div>
                            }
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose} centered>

                        <Modal.Body className='py-4'>

                            {modelType === "getPaid" ? <>
                                <div className='text-center mb-4'>
                                    <h4> Are you sure?</h4>
                                    <h5>You want to mark this as paid</h5>
                                </div>

                                <div className='text-center mt-2'>
                                    <button className='btn btn-danger btn-sm me-3' name={sessionCloseId} onClick={confirmClose}> Confirm</button>
                                    <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                                </div>
                            </> : null}
                            {modelType === "cancelSession" ? <>
                                <div className='text-center mb-4'>
                                    <h4> Are you sure?</h4>
                                    <h5>You want to cancel this transaction</h5>
                                </div>

                                <div className='text-center mt-2'>
                                    <button className='btn btn-danger btn-sm me-3'
                                        name={sessionCloseId}
                                        onClick={confirmCancel}
                                    > Confirm</button>
                                    <button className='btn btn-outline-danger btn-sm'
                                        onClick={handleClose}
                                    > Cancel</button>
                                </div>
                            </> : null}

                        </Modal.Body>


                    </Modal>
                </main>}
        </>
    )
}
