export const energyfunctioncustom = (unit, type) => {

    if (type === "NumberOnly") {
        return unit > 0 && unit < 1000000 ? (unit / 1000).toFixed(3) :
            unit >= 1000000 && unit < 1000000000 ? (unit / 1000000).toFixed(3) :
                unit >= 1000000000 && unit < 1000000000000 ? (unit / 1000000).toFixed(3) :
                    unit >= 1000000000000 ? (unit / 1000000000000).toFixed(3) :
                        "0"


    } else {
        return unit > 0 && unit < 1000000 ? "kWh" :
            unit >= 1000000 && unit < 1000000000 ? "MWh" :
                unit >= 1000000000 && unit < 1000000000000 ? "GWh" :
                    unit >= 1000000000000 ? "TWh" :
                        "0kWh"
    }



}
