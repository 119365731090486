export default {

    // logo: require("./logo.svg").default,
    logo: require("./logo.png"),
    stripe: require("./stripeLogo.png"),
    defaultCard: require("./defaultCard.png"),
    profileImg: require("./profile-user.png"),

    credited: require("./credited.svg").default,
    debited: require("./debited.svg").default,
    refunded: require("./refunded.svg").default,
     
    online: require("./online.svg").default,
    temporary_offline: require("./offline.svg").default, 
    under_maintenance: require("./maintainance.svg").default,
    coming_soon: require("./comingSoon.svg").default,

    moneyBag: require("./moneyBag.svg").default,
    bolt: require("./bolt.svg").default,

}