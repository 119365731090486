import React, { useEffect, useState } from 'react'
import Index from '../../../../Assets/Images/Index'
import Form from 'react-bootstrap/Form';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';

export default function Payment() {
    // GET_PAYMENT_GATEWAY 
    const [loading, setloading] = useState(true)
    const [paymentGateway, setPaymentGateway] = useState({})
    // const [mode, setMode] = useState(false)
    const [detail, setDetail] = useState({
        mode: "",
        secret_key: "",
        published_key: "",
    })

    const getPaymentGateway = () => {
        Api.GetApi(endPoints.GET_PAYMENT_GATEWAY, "").then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setPaymentGateway(res.data.data)
                let sk, pk = ""
                if (res.data.data?.mode === "test") {
                    sk = res.data.data?.test_secret_key
                    pk = res.data.data?.test_published_key
                } else {
                    sk = res.data.data?.production_secret_key
                    pk = res.data.data?.production_published_key
                }
                setDetail({
                    mode: res.data.data?.mode,
                    secret_key: sk,
                    published_key: pk
                })
                setloading(false)
            }
        })
    }
    const updateProductionMode = (e) => {
        let sk, pk, md = ""

        if (e.target.checked === true) {
            md = "test"
            sk = paymentGateway.test_secret_key
            pk = paymentGateway.test_published_key
        } else {
            md = "production"
            sk = paymentGateway.production_secret_key
            pk = paymentGateway.production_published_key
        }
        setDetail({
            mode: md,
            // mode: e.target.checked,
            secret_key: sk,
            published_key: pk
        })
    }

    const updateKeyValue = (e) => {
        setDetail({ ...detail, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {


        Api.PutApi(endPoints.GET_PAYMENT_GATEWAY, detail).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else { 
                getPaymentGateway()
                Api.Alert("Updated sucessfully", "success")
            }
        })


    }

    useEffect(() => {
        getPaymentGateway()
    }, [])
    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Payment Gateway</h1>
            </div>
            {loading ?
                <div className="loader-line"></div> :
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            {/* <h5 className="card-title"> </h5> */}
                            <div className="d-flex justify-content-between align-items-center mb-3 userdetailTopRow">
                                <div className='userdetailTopRow__left mb-3 mb-md-0'>
                                    <div className="paymentLogo">
                                        <img src={Index.stripe} alt="Stripe" />
                                    </div>
                                </div>
                                <div className='userdetailTopRow__right'>
                                    <Form className='d-flex align-items-center customMode'>
                                        <label className="me-2">Production Mode</label>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            // id={data?._id?._id} 
                                            label="Test Mode"
                                            checked={detail.mode === "test" ? true : false}
                                            // value={}
                                            onChange={(e) => updateProductionMode(e)}
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className=''>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="">Secret key</label>
                                    <input placeholder="" type="text" name="secret_key" className="form-control" value={detail.secret_key || ""} onChange={updateKeyValue} />
                                </div><div className="mb-3"><label className="form-label" htmlFor="">Publishable key</label>
                                    <input placeholder="" type="text" name="published_key" className="form-control" value={detail.published_key || ""} onChange={updateKeyValue} />
                                </div>
                                <button type="submit" className={`btn btn-primary ${detail.secret_key === "" && detail.published_key ==="" ? "disabled" : null }`}
                                
                                        
                                onClick={handleSubmit}>Update</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </main>
    )
}
