import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import Index from '../../../../Assets/Images/Index';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


import CurrencyFormat from 'react-currency-format';
import { dataValue } from '../../../../CommonReducer';
import { useDispatch } from 'react-redux';

export default function Profile() {
    const [editProfileTab, seteditProfileTab] = useState(false)
    const [editPasswordTab, seteditPasswordTab] = useState(false)
    const [errorObj, setErrorObj] = useState({
        error: false,
        errorMessage: "",
    })
    const [loading, setloading] = useState(true)
    const dispatch = useDispatch()

    const toggleEditState = () => {
        seteditProfileTab(!editProfileTab)
        getProfileDetail("", Api.GetApi)
    }
    const togglePasswordState = () => {
        setErrorObj({ error: false, errorMessage: "", });
        seteditPasswordTab(!editPasswordTab)
    }
    const [editPhone_number, seteditPhone_number] = useState("")
    const [userProfil, setUserProfil] = useState({
        first_name: "",
        last_name: "",
        countryCode: "",
        phone_number: "",
        email: "",
        old_password: "",
        new_password: "",
        match_password: ""

    })
    const [password, setpassword] = useState({
        old_password: false,
        new_password: false,
        match_password: false,
    })
    const toggleShowPassword = (name) => {
        setpassword({ ...password, [name]: !password[name] })
    }


    const getProfileDetail = (data, ApiType) => {
        ApiType(endPoints.ADMIN, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                if (res.data?.data?.country_code) {
                    seteditPhone_number(`+${res.data?.data?.country_code} ${res.data?.data?.phone_number}`)
                } else {
                    seteditPhone_number(res.data?.data?.phone_number)
                }
                let resData = {
                    ...res.data.data,
                    countryCode: res.data.data?.country_code
                }
                setUserProfil(resData)
                setloading(false)
                let AUTH_DATA = {}
                AUTH_DATA["email"] = res.data.data?.email
                AUTH_DATA["name"] = res.data.data?.first_name
                AUTH_DATA["lname"] = res.data.data?.last_name
                // + resData?.last_name
                dispatch(dataValue({ 'AUTH_DATA': AUTH_DATA }))
                if (ApiType === Api.PutApi) {
                    Api.Alert("Updated sucessfully", "success")
                    seteditProfileTab(false)
                    seteditPasswordTab(false)

                }
            }
        })
    }

    const handlePhonechange = (value, data) => {
        seteditPhone_number(`+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`)

        setUserProfil({
            ...userProfil,
            //  'phone_number': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`,
            'phone_number': parseInt(value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)),
            'countryCode': parseInt(data.dialCode)
        })
    }

    const handleInputchange = (e) => {
        setUserProfil({ ...userProfil, [e.target.name]: e.target.value })
    }


    const [errors, setErrors] = useState(false)
    const [invalidPhone, setInvalidPhone] = useState(false)


    const handleProfileSubmit = (event) => {
        event.preventDefault();
        if (!userProfil?.phone_number || userProfil?.phone_number === "" || userProfil?.phone_number.toString().length < 9 || document.querySelectorAll(".form-control.invalid-number").length > 0) {
            setErrors(true)
            setInvalidPhone(true)
        }
        else {
            setErrors(false)
            setInvalidPhone(false)
            let formData = new FormData();
            formData.append('first_name', userProfil.first_name);
            formData.append('last_name', userProfil.last_name);
            formData.append('countryCode', userProfil.countryCode);
            formData.append('phone_number', userProfil.phone_number);
            formData.append('email', userProfil.email);
            getProfileDetail(formData, Api.PutApi)
        }

    }
    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        var reg = new RegExp(
            "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
        );
        var validPass = reg.test(userProfil.new_password);

        let formData = new FormData();
        if (userProfil.old_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter old password",
            });
        } else if (!validPass) {
            setErrorObj({
                error: true,
                errorMessage: "Create a secure password with at least 8 characters, including uppercase, lowercase, numbers, and symbols",
            });
        } else if (userProfil.new_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter new Password",
            });
        } else if (userProfil.match_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter confirm password",
            });
        } else if (userProfil.new_password !== userProfil.match_password) {
            setErrorObj({
                error: true,
                errorMessage: "New password and confirm password should be same",
            });
        }
        else {

            setErrorObj({ error: false, errorMessage: "", });

            // formData.append('email', userProfil.email);
            // formData.append('old_password', userProfil.old_password);
            // formData.append('new_password', userProfil.new_password);




            let obj = {
                email: userProfil.email,
                old_password: userProfil.old_password,
                new_password: userProfil.new_password,
            }

            getProfileDetail(obj, Api.PutApi)



        }


    }



    useEffect(() => {
        getProfileDetail("", Api.GetApi)
    }, [])

    // ADMIN
    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Profile</h1>
            </div>

            <div className="row profile">
                <div className="col-xl-6">
                    <div className="card profile-card">
                        <div className="card-body  ">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className=" "><h5 className="card-title m-0"> {editProfileTab ? "Update Profile" : "Profile"}</h5></div>
                                <div className=" "> <button className={`btn ${editProfileTab ? "btn-outline-danger" : "btn-primary"}`} onClick={toggleEditState}>{editProfileTab ? <Icon.XLg /> : <Icon.Pencil />}</button></div>
                            </div>
                            {/* <div className='mb-2'>
                                <div>
                                    <img src={Index.profileImg} alt="Profile" className=" " />
                                </div>
                                <div className="pt-2">
                                    <button className="btn btn-primary btn-sm" title="Upload new profile image"><Icon.Upload /></button>
                                    <button className="btn btn-danger btn-sm ms-2" title="Remove my profile image"><Icon.Trash /></button>
                                </div>
                            </div> */}
                            <form className="row g-3" onSubmit={handleProfileSubmit}>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="" className="form-label">First Name*</label>
                                    <input type="text" name='first_name' className="form-control" onChange={handleInputchange} value={userProfil?.first_name} readOnly={editProfileTab ? false : true} id="" required />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="" className="form-label">Last Name*</label>
                                    <input type="text" name='last_name' className="form-control" onChange={handleInputchange} value={userProfil?.last_name} readOnly={editProfileTab ? false : true} id="" required />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="" className="form-label">Phone Number*</label>

                                    {editProfileTab ?
                                        <>
                                            <PhoneInput

                                                className={invalidPhone || !userProfil?.phone_number || userProfil?.phone_number == "" || userProfil?.phone_number.toString().length < 9 || userProfil?.phone_number.toString().length > 12 ? "is-invalid" : ""}
                                                enableSearch={true}
                                                disableAreaCodes={false}
                                                disableSearchIcon={true}
                                                disabled={editProfileTab ? false : true}
                                                country={"my"}
                                                value={editPhone_number.toString()}
                                                inputExtrastate={{ name: 'mobile', required: true }}
                                                // inputProps={{ maxLength: '17' }}
                                                onChange={(e, x) => handlePhonechange(e, x)}
                                                isValid={(value, data) => {
                                                    if (
                                                        value
                                                            .toString().replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length < 9 &&
                                                        value
                                                            .toString().replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length > 0
                                                    ) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder=""
                                                countryCodeEditable={false}
                                                defaultMask=".. ... ...."
                                            />
                                            {errors && (!userProfil?.phone_number || userProfil?.phone_number == "") ?
                                                <div className='invalid-feedback'>Please enter phone number</div>
                                                : null}

                                            {errors && invalidPhone && userProfil?.phone_number && userProfil?.phone_number != "" ?
                                                <div className='invalid-feedback'>Enter valid phone number</div>
                                                : null
                                            }
 
                                        </>
                                        :
                                        <input type="text" name='name' className="form-control"
                                            onChange={(e) => handleInputchange(e, "")} value={editPhone_number} readOnly disabled id="" required />
                                    }



                                </div>
                                <div className="col-12">
                                    <label htmlFor="" className="form-label">Email*</label>
                                    <input type="email" name='email' className="form-control" onChange={handleInputchange} value={userProfil?.email} readOnly={editProfileTab ? false : true} id="" required />
                                </div>
                                {/* <div className="col-12">
                                    <label htmlFor="" className="form-label">Password</label>
                                    <input type="password" name='password' className="form-control" readOnly={editProfileTab ? false : true} id="" />
                                </div> */}
                                {editProfileTab ?
                                    <div className="text-center1">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button className="btn btn-secondary ms-2" onClick={toggleEditState}>Cancel</button>
                                    </div> : null}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card profile-card">
                        <div className="card-body  ">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className=" "><h5 className="card-title m-0">Change Password</h5></div>
                                <div className=" "> <button className={`btn ${editPasswordTab ? "btn-outline-danger" : "btn-primary"}`} onClick={togglePasswordState}>{editPasswordTab ? <Icon.XLg /> : <Icon.Pencil />}</button></div>
                            </div>
                            {errorObj.error ? <div className="invalid-feedback mb-2 d-block">{errorObj.errorMessage}</div> : null}
                            {editPasswordTab ?
                                <form className="row g-3" onSubmit={handlePasswordSubmit}>

                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Current Password*</label>
                                        <div className='passwordField'>
                                            <div className='passwordField'>
                                                <input
                                                    type={password.old_password ? "text" : "password"} name='old_password' className="form-control" value={userProfil?.old_password} onChange={handleInputchange} required />
                                                <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("old_password")}>
                                                    {password.old_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">New Password*</label>
                                        <div className='passwordField'>
                                            <input type={password.new_password ? "text" : "password"} name='new_password' className="form-control" value={userProfil?.new_password} onChange={handleInputchange} id="" required />
                                            <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("new_password")}>
                                                {password.new_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Confirm Password*</label>
                                        <div className='passwordField'>
                                            <input type={password.match_password ? "text" : "password"} name='match_password' className="form-control" value={userProfil?.match_password} onChange={handleInputchange} id="" required />
                                            <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("match_password")}>
                                                {password.match_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                            </button>
                                        </div>
                                    </div>


                                    <div className="text-center1">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" className="btn btn-secondary ms-2" onClick={togglePasswordState}>Cancel</button>
                                    </div>
                                </form> : null}
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}
