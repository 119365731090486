import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import _ from "underscore"
import { Form, Pagination } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { energyfunction } from '../../../../Config/energyFunction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addYears, set } from "date-fns";
import axios from 'axios';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';

const options = [
    { name: "Credit", value: "credit", },
    { name: "Debit", value: "debit", },
    { name: "Refund", value: "refund", }
]
const options2 = [
    { name: "Debit", value: "debit", },
    { name: "Credit", value: "credit", }
]

const maxdescLength = 300
export default function TransactionDetail() {
    const searchParams = new URLSearchParams(window.location.search)
    const [descLength, setdescLength] = useState(0)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const param = useParams()
    const [UserSession, setUserSession] = useState([])
    const [popData, setPopData] = useState({})
    const [show, setShow] = useState(false);
    const [loadingBtn, setloadingBtn] = useState(false)

    const [modalType, setmodalType] = useState("")
    const [errors, seterrors] = useState(false)
    const [popValue, setpopValue] = useState({
        type: "debit",
        note: "",
        amount: ""
    })
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        type: searchParams.get("type") || "",
        search: searchParams.get("search") || "",
    })
    const [count, setCount] = useState(1)


    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : "",
    })
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const [userId, setuserId] = useState()
    const getUserSession = (id, param) => {
        Api.GetApi(`${endPoints.USER_TRANSACTION}/${id}/${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {

                if (res.data.data.transactions?.result?.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data.transactions?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }
                setuserId(res.data.data?.user?._id)
                setUserSession(res.data.data)
                setloading(false)
            }
        })
    }
    const [refundid, setrefundid] = useState()

    const openRefundPop = (data) => {
        setmodalType("refund")
        setrefundid(data)
        setShow(true)
        console.log("data", data)
    }
    const walletAction = () => {
        setmodalType("walletAction")
        setShow(true)
    }

    const openNotePop = (data) => {
        console.log(data)
        setmodalType("note")
        setShow(true)
        setPopData(data)

    }


    const confirmRefund = () => {
        setloadingBtn(true)
        console.log("refundid", refundid)
        Api.PutApi(endPoints.USER_REFUND, { transactionId: refundid }).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
                setloadingBtn(false)
                
            } else {
                Api.Alert(res.data.data, "success")
                getUserSession(param.id, location.search)
                setloadingBtn(false)
                handleClose()
            }
        })

    }
    const onSelectChange = (e) => {
        setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
        setparam([e.target.name], e.target.value)
        setparam("page", 1)
        // DashData(`?type=${params.type}&sort_value=${params.sort_value}&page=${params.page}`)
    }

    const clearFilter = () => {
        setParams({
            ...params,
            type: "",
            page: 1,
        })

        deleteparam("page")
        deleteparam("type")
    }
    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        deleteparam("search")
    }
    const handleClose = () => {
        setShow(false);
        seterrors(false)

        setpopValue({
            ...popValue,
            type: "debit",
            amount: "", note: ""
        })
    }
    const handleShow = () => setShow(true);

    const sendDataToPop = (data) => {
        setPopData(data)
        handleShow()
    }


    const [dateError, setdateError] = useState(false)
    const filterDateChange = () => {
        if (filterDate?.from == "" || filterDate?.to == "") {
            setdateError(true)
        } else {
            setdateError(false)
            setParams({ ...params, "page": 1, "from": moment(filterDate?.from).format("YYYY-MM-DD"), "to": moment(filterDate?.to).format("YYYY-MM-DD") })
            setparam("from", moment(filterDate?.from).format("YYYY-MM-DD"))
            setparam("to", moment(filterDate?.to).format("YYYY-MM-DD"))
            setparam("page", 1)
        }
    }
    const onDateChange = (date, name) => {
        if (name === "from") {
            setfilterDate({ ...filterDate, [name]: date, to: "" })
        } else {
            setfilterDate({ ...filterDate, [name]: date })
        }
    }
    const clearDate = () => {
        setfilterDate({ from: "", to: "" });
        setParams({ ...params, from: "", to: "" });
        deleteparam("from")
        deleteparam("to")
    };

    const handleChange = (e) => {
        setpopValue({ ...popValue, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        setdescLength(popValue.note.length)
    }, [popValue])


    const submitWalletAction = () => {
        setloadingBtn(true)
        console.log("loadingBtn", loadingBtn)

        if (popValue.amount === "" || popValue.amount == "0") {
            seterrors(false)
        } else if (popValue.note === "") {
            seterrors(false)
        // } else if (popValue.type === "debit" && (!UserSession?.wallet || parseFloat(popValue.amount) > UserSession?.wallet?.balance)) {
        } else if (popValue.type === "debit" && (!UserSession?.wallet)) {
            seterrors(false)
        }
        else {
           
            let data = {}
            data = {
                type: popValue.type,
                note: popValue.note,
                amount: parseFloat(popValue.amount),
                user_id: userId
            }
            updateWalletAction(data)
            
        }

    }

    const updateWalletAction = (data) => {

        console.log("data:----", data)

        Api.PostApi(endPoints.WALLETACTION, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
                setloadingBtn(false)
            } else {
                console.log("res.data.data", res.data.data)
                Api.Alert(res.data.data, "success")
                handleClose()
                getUserSession(param.id, location.search)
                setloading(false)
                setloadingBtn(false)
            }
        })

    }


    const searchUser = (e) => {
        deleteparam("page")
        setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
        if (e.target.value !== "") {
            setparam([e.target.name], e.target.value)
        } else {
            deleteparam("search")
        }
    }
    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])

    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }
    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])



    const handleExport = () => {
        axios({
            method: 'get',
            url: `${Api.appBaseURL}${endPoints.USER_TRANSACTION}/csv/${userId}${location.search}`,
            responseType: 'blob',
            headers: {
                "token": Api.isLogIn().token,
            }
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {
                type: 'text/csv'
            });

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `charge_station${filterDate?.from && filterDate?.to ? `_${moment(filterDate?.from).format("YYYY-MM-DD")}_to_${moment(filterDate?.to).format("YYYY-MM-DD")}` : `_${moment(new Date()).format("YYYY-MM-DD")}`}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }).catch((err) => {
            Api.Alert(err, "error")
        });
    }
    console.log("popValue", popValue)
    console.log("userId", userId)

    useEffect(() => {
        setparam("page", params.page)
        getUserSession(param.id, location.search)
    }, [location.search])
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                        <h1>User  Wallet History</h1>
                        {UserSession?.transactions?.result && UserSession?.transactions?.result.length > 0 ? <Button variant="primary" size='sm' className='me-2 align-items-center' onClick={handleExport}  >
                            Export CSV   </Button> : null}
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className="d-flex justify-content-between mb-0 mb-md-3 ">
                                <div className='mb-3'>
                                    <Link onClick={(e) => { e.preventDefault(); navigate(-1) }} className="card-title pb-0"> <Icon.ArrowLeft /> <span className='ms-2 userName'>
                                        {UserSession?.user?.first_name} {UserSession.user?.last_name || "Back"} </span></Link>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <h5 className='mb-0'>
                                        <Icon.Wallet className="me-2" />
                                        <CurrencyFormat value={UserSession?.wallet ? parseFloat(UserSession?.wallet?.balance).toFixed(2) : "0"} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                    </h5>
                                    <Button variant="primary" size='sm' className='ms-2 align-items-center' onClick={walletAction} >Wallet Action</Button>
                                </div>
                            </div>

                            <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow userdetailTopRow--sessionpage">
                                <div className='userdetailTopRow__left'>
                                    <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row" >
                                        <div className="search-bar pe-1  ">
                                            <div className="search-form d-flex align-items-center" >
                                                <input type="text" className='form-control' name="search"
                                                    placeholder="Search by transaction id"

                                                    value={params.search} title="Enter search keyword" onChange={searchUser} />
                                                <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                            </div>
                                        </div>
                                        {params.search !== "" ?
                                            <button type="button" className="textClear nowrap " onClick={clearSearch}>Clear Search</button> : null
                                        }
                                    </div>
                                    <div className='dateFilterBox flex-wrap  d-flex align-items-start  me-md-3'>
                                        <div className="dateFilterBox__col me-2 mb-1">
                                            <DatePicker
                                                className="reportingSelectBox"
                                                selected={filterDate.from}
                                                onChange={(date) => onDateChange(date, "from")}
                                                selectsStart
                                                startDate={filterDate.from}
                                                endDate={filterDate.to}
                                                placeholderText="Start Date"
                                                maxDate={new Date()}
                                            />
                                            <div className='error small'>
                                                {dateError && filterDate?.from == "" ? "Please choose start date " : null}
                                            </div>
                                        </div>
                                        <div className="dateFilterBox__col me-2 mb-1">
                                            <DatePicker
                                                className="reportingSelectBox"
                                                selected={filterDate.to}
                                                onChange={(date) => onDateChange(date, "to")}
                                                selectsEnd
                                                startDate={filterDate.from}
                                                endDate={filterDate.to}
                                                minDate={filterDate.from}
                                                placeholderText="End Date"
                                                maxDate={addYears(new Date(filterDate?.from), 1) < new Date() ? addYears(new Date(filterDate?.from), 1) : new Date()}
                                            />
                                            <div className='error small'>
                                                {dateError && filterDate?.to == "" ? "Please choose end date " : null}
                                            </div>
                                        </div>
                                        <div className="dateFilterBox__btncol">
                                            <button className='btn btn-primary btn-sm' onClick={filterDateChange}>Filter</button>
                                            {filterDate.to !== "" || filterDate.from !== ""
                                                ? <button type="button" className="textClear" onClick={clearDate}>Clear Date</button> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-md-flex align-items-center userdetailTopRow__right userdetailTopRow__right--sessionpage" >
                                    <div className='my-2'>
                                        <select className='sortingSelect' name='type' value={params.type} onChange={onSelectChange}>
                                            <option disabled defaultValue value="">Filter</option>
                                            {options.map((data, index) => {
                                                return (
                                                    <option key={index} value={data.value}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                        {params.type !== "" ?
                                            <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null}
                                    </div>
                                </div>
                            </div>
                            {UserSession?.transactions?.result && UserSession?.transactions?.result.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th className='sorting' scope="col">Transaction  Id</th>
                                                    <th scope="col">Payment Type </th>
                                                    <th scope="col">Payment For </th>
                                                    <th scope="col">Date </th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {UserSession?.transactions?.result.map((data, index) => {
                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(params.page - 1) * 20 + updInd}</td>
                                                            <td>{data?.transaction_id}</td>
                                                            <td className='text-capitalized'>
                                                                <span> {data?.payment_type}</span>
                                                                {data?.payment_type === "debit" && data?.is_refund === false ?
                                                                    <>
                                                                        <button className='btn btn-sm btn-outline-primary ms-3' onClick={() => openRefundPop(data?.transaction_id)} > Refund</button>
                                                                    </> : null}
                                                            </td>
                                                            <td className="text-capitalize"><span className='d-inline-block' style={{ minWidth: "100px" }}>{data?.payment_method_details?.paymentFor ?? "Topup"}</span>
                                                                {data?.payment_method_details?.note ?
                                                                    <Button className='btn btn-primary btn-sm ms-1' onClick={() => openNotePop(data?.payment_method_details?.note)}><Icon.Eye /></Button>
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                {formatDate(data?.createdAt)}
                                                                {/* {moment(data?.createdAt).format("llll")} */}
                                                            </td>
                                                            <td>
                                                                <CurrencyFormat value={parseFloat(data?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></td>
                                                            <td className='text-capitalized'>
                                                                <span className={data?.status === "succeeded" || data?.status === "succeed" ? 'badge bg-success' : "badge bg-danger"}>
                                                                    {data?.status === "succeeded" || data?.status === "succeed" ? "Success" : data?.status}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />
                                    </div>
                                </> : <>
                                    <div className='text-center py-3'>
                                        <h3>No transaction history found</h3>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title> {modalType === "refund" ? "Refund" : modalType === "note" ? "Note" : "Wallet Action"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {modalType === "refund" ?
                                <>
                                    <div className='text-center mb-4'>
                                        <h4> Are you sure?</h4>
                                        <h5> You want to refund this transaction.</h5>
                                    </div>
                                </>
                                :
                                null}



                            {modalType === "walletAction" ? <>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Form.Group className="mb-3" >
                                            <Form.Label><strong>Transaction type*</strong></Form.Label>
                                            {/* <Form.Control type="text" name="state" placeholder="" onChange={(e) => handleInputchange(e, "address")} value={value?.address?.state} /> */}
                                            <Form.Select
                                                name='type'
                                                onChange={handleChange}
                                                value={popValue.type}>
                                                {options2.map((key, index) => {
                                                    return (
                                                        <option value={key.value} key={index}>{key.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {errors && popValue.type == "" ?
                                                <div className='invalid-feedback d-block'>Please select transaction type</div>
                                                : null}
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group className="mb-3" >
                                            <Form.Label><strong>Amount*</strong></Form.Label>
                                            <Form.Control type="number" name="amount" placeholder="0.00" maxLength="10" onChange={handleChange}
                                                value={popValue.amount}
                                                isInvalid={errors && popValue?.amount == ""}
                                                min="0"
                                                step="1"
                                            />
                                            {errors && popValue?.amount == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter amount</Form.Control.Feedback> : null}
                                            {errors && popValue?.amount == "0" || errors && popValue?.amount < "0" ? <Form.Control.Feedback type="invalid" className='d-block'>Amount should be more than 0</Form.Control.Feedback> : null}
                                            {errors && (!UserSession?.wallet || parseFloat(popValue?.amount) > UserSession?.wallet?.balance) ? <Form.Control.Feedback type="invalid" className='d-block'>Debit amount should not be more than Wallet balance</Form.Control.Feedback> : null}
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group className="mb-3" >
                                            <Form.Label><strong>Note*</strong></Form.Label>
                                            <Form.Control className='resize-none' as="textarea" name='note' onChange={handleChange} maxLength={maxdescLength} rows={4}
                                                isInvalid={errors && popValue?.note == ""} />
                                            <small>{descLength}/{maxdescLength}</small>
                                            {errors && popValue?.note == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter note</Form.Control.Feedback> : null}
                                        </Form.Group>
                                    </div>



                                </div>

                            </> : null}
                            {modalType === "note" ? <>
                                <div className='text-center mb-4'>
                                    <p>{popData}</p>
                                </div>
                            </> : null}

                            <div className={modalType === "walletAction" ? "text-end mt-2" : 'text-center mt-2'}>
                                {modalType === "refund" ? <button className={loadingBtn ? "btn btn-primary  me-3 disabled" : "btn btn-primary  me-3"} onClick={confirmRefund}> Confirm</button> :
                                    modalType === "walletAction" ?

                                        <button disabled={loadingBtn} className={loadingBtn ? "btn btn-primary  me-3 disabled" : "btn btn-primary  me-3"} onClick={submitWalletAction} > Confirm</button> : null
                                }

                                <button className='btn btn-outline-danger ' onClick={handleClose} > Cancel</button>
                            </div>

                        </Modal.Body>
                    </Modal>
                </main>}
        </>
    )
}
