import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addYears } from "date-fns";
import axios from 'axios';
import Index from '../../../../Assets/Images/Index';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';
// SESSION

const options = [
    { name: "Name", value: "user.fullName", },
    { name: "Email", value: "user.email", },
    { name: "Date", value: "createdAt", }
]
const sortTypes = [
    { name: "Ascending ", value: "asc", },
    { name: "Descending  ", value: "desc", },
]

const filterTypes = [
    { name: "Card", value: "Card" },
    { name: "Wallet", value: "Wallet" }
]
export default function Topup() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [activeSession, setActiveSession] = useState({})
    const [sessionCloseId, setsessionCloseId] = useState()
    const [show, setShow] = useState(false);
    let limit = 20
    const [params, setParams] = useState({
        sort_key: searchParams.get("sort_key") || "",
        sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
    })
    const [count, setCount] = useState(1)
    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : "",
    })
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const getactiveSession = (param) => {
        Api.GetApi(`${endPoints.TOPUPLIST}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                // console.log("res.data.", res.data.data)  
                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }

                setActiveSession(res.data?.data[0]?.result)
                setloading(false)
            }
        })
    }

    const clearFilter = () => {
        setParams({
            ...params,
            sort_key: "",
            sort_value: "asc",
        })
        deleteparam("sort_key")
        deleteparam("sort_value")
    }
    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        deleteparam("search")
    }

    const [dateError, setdateError] = useState(false)
    const filterDateChange = () => {
        if (filterDate?.from == "" || filterDate?.to == "") {
            setdateError(true)
        } else {
            setdateError(false)
            setParams({ ...params, "page": 1, "from": moment(filterDate?.from).format("YYYY-MM-DD"), "to": moment(filterDate?.to).format("YYYY-MM-DD") })
            setparam("from", moment(filterDate?.from).format("YYYY-MM-DD"))
            setparam("to", moment(filterDate?.to).format("YYYY-MM-DD"))
            setparam("page", 1)

        }
    }
    const onDateChange = (date, name) => {
        if (name === "from") {
            setfilterDate({ ...filterDate, [name]: date, to: "" })
        } else {
            setfilterDate({ ...filterDate, [name]: date })
        }
    }
    const clearDate = () => {
        setfilterDate({ from: "", to: "" });
        setParams({ ...params, from: "", to: "" });
        deleteparam("from")
        deleteparam("to")
    };

    const searchUser = (e) => {
        deleteparam("page")
        setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
        if (e.target.value !== "") {
            setparam([e.target.name], e.target.value)
        } else {
            deleteparam("search")
        }
    }

    const onSelectChange = (e) => {
        setParams({ ...params, [e.target.name]: e.target.value })
        if (e.target.name == "sort_value") {
            if (params.sort_key != "") {
                setparam("sort_value", e.target.value)
            } else {
                deleteparam("sort_value")
            }
        } else {
            if (e.target.name == "sort_key") {
                setparam([e.target.name], e.target.value)
                setparam("sort_value", params.sort_value)
            }
            else {
                setparam([e.target.name], e.target.value)
            }
        }
        // DashData(`?sort_key=${params.sort_key}&sort_value=${params.sort_value}&page=${params.page}`)
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (params.search != "") {
                setparam('search', params.search)
            }
            // DashData(`?search=${params.search}`)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [params.search]) 
    
    const updateBlockStat = (data) => {
        // STOP_SESSION
        // console.log(data)
        setsessionCloseId(data)
        handleShow()
    }
    const confirmClose = (e) => {
        stopSessionFun(e.target.name)
    }

    const stopSessionFun = (id) => {
        // console.log("sds", id)

        Api.PutApi(`${endPoints.STOP_SESSION}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                handleClose()
                Api.Alert("Session stoped successfully", "success")
                setloading(true)
                setTimeout(() => {
                    getactiveSession(location.search)
                }, 2000);
            }
        })

    }
    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        }else{            
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }
    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])


    const handleExport = () => {
        axios({
            method: 'get',
            url: `${Api.appBaseURL}${endPoints.ADMIN_SESSION}/csv${location.search}`,
            responseType: 'blob',
            headers: {
                "token": Api.isLogIn().token,
            }
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {
                type: 'text/csv'
            });

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `charge_station${filterDate?.from && filterDate?.to ? `_${moment(filterDate?.from).format("YYYY-MM-DD")}_to_${moment(filterDate?.to).format("YYYY-MM-DD")}` : `_${moment(new Date()).format("YYYY-MM-DD")}`}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }).catch((err) => {
            Api.Alert(err, "error")
        });
    }
    var moment = require('moment-timezone');

    useEffect(() => {
        if (!searchParams.get("from") && !searchParams.get("to")) {
            clearDate()
        }
        if (!searchParams.get("sort_key")) {

            clearFilter()
        } if (!searchParams.get("search")) {

            clearSearch()
        }
        if (searchParams.get("page")  && searchParams.get("page")  != params.page) {  
            setParams({ ...params, page: searchParams.get("page")  })
            console.log("page param missing")
            console.log("param: ", params.page) 
        }
        getactiveSession(location.search)

    }, [location.search])
    console.log("params", params.page)
    console.log("searchParams.get(sort_key)", searchParams.get("page"))
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                        <h1>Top Up List</h1>
                        {/* {activeSession && activeSession.length > 0 ? <Button variant="primary" size='sm' className='me-2 align-items-center' onClick={handleExport} >
                                Export CSV   </Button> : null} */}
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow userdetailTopRow--sessionpage">
                                <div className='userdetailTopRow__left'>
                                    <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row" >
                                        <div className="search-bar pe-1  ">
                                            <div className="search-form d-flex align-items-center" >
                                                <input type="text" className='form-control' name="search"
                                                    placeholder="Search by user/email/payment id"

                                                    value={params.search} title="Enter search keyword" onChange={searchUser} />
                                                <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                            </div>
                                        </div>
                                        {params.search !== "" ?
                                            <button type="button" className="textClear nowrap " onClick={clearSearch}>Clear Search</button> : null
                                        }
                                    </div>
                                    <div className='dateFilterBox flex-wrap  d-flex align-items-start  me-md-3'>
                                        <div className="dateFilterBox__col me-2 mb-1">
                                            <DatePicker
                                                className="reportingSelectBox"
                                                selected={filterDate.from}
                                                onChange={(date) => onDateChange(date, "from")}
                                                selectsStart
                                                startDate={filterDate.from}
                                                endDate={filterDate.to}
                                                placeholderText="Start Date"
                                                maxDate={new Date()}
                                            />
                                            <div className='error small'>
                                                {dateError && filterDate?.from == "" ? "Please choose start date " : null}
                                            </div>
                                        </div>
                                        <div className="dateFilterBox__col me-2 mb-1">
                                            <DatePicker
                                                className="reportingSelectBox"
                                                selected={filterDate.to}
                                                onChange={(date) => onDateChange(date, "to")}
                                                selectsEnd
                                                startDate={filterDate.from}
                                                endDate={filterDate.to}
                                                minDate={filterDate.from}
                                                placeholderText="End Date"
                                                maxDate={addYears(new Date(filterDate?.from), 1) < new Date() ? addYears(new Date(filterDate?.from), 1) : new Date()}
                                            />
                                            <div className='error small'>
                                                {dateError && filterDate?.to == "" ? "Please choose end date " : null}
                                            </div>
                                        </div>
                                        <div className="dateFilterBox__btncol">
                                            <button className='btn btn-primary btn-sm' onClick={filterDateChange}>Filter</button>
                                            {filterDate.to !== "" || filterDate.from !== ""
                                                ? <button type="button" className="textClear" onClick={clearDate}>Clear Date</button> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-md-flex align-items-center userdetailTopRow__right userdetailTopRow__right--sessionpage" >

                                    <div className='my-2'>



                                        <select className='sortingSelect' name='sort_key' value={params.sort_key} onChange={onSelectChange}>
                                            <option disabled defaultValue value="">Sort By</option>
                                            {options.map((data, index) => {
                                                return (
                                                    <option key={index} value={data.value}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                        {searchParams.get("sort_key") && searchParams.get("sort_key") !== ""  ?
                                            <select className='sortingSelect' name='sort_value' value={params.sort_value} onChange={onSelectChange}>

                                                {sortTypes.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.name}</option>
                                                    )
                                                })}
                                            </select> : null}
                                        {searchParams.get("sort_key") && searchParams.get("sort_key") !== ""  ?
                                            <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null}
                                    </div>
                                </div>
                            </div>
                            {activeSession && activeSession.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">User Name</th>
                                                    <th scope="col">Email</th>
                                                    <th className='sorting' scope="col">Payment Id</th>
                                                    <th className='sorting' scope="col">Amount</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Card</th>
                                                    <th scope="col">Payment Status</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeSession.length > 0 && activeSession.map((data, index) => {
                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index} >
                                                            <td>   {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                            <td>{data?.user?.fullName || "---"}</td>
                                                            <td>{data?.user?.email || "---"}</td>
                                                            <td>{data?.payment_details?.id}</td>
                                                            <td>
                                                                <CurrencyFormat value={parseFloat(data?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                                {/* {data?.payment_details?.amount} */}
                                                            </td>
                                                            <td>{formatDate(data?.createdAt)}</td>
                                                            <td>
                                                                <div className='cardDetail' >
                                                                    <span className='cardDetail__icon'><img src={data?.image || Index.defaultCard} /></span>
                                                                    <span className='cardDetail__text'>{data?.payment_method_details?.card?.last4}</span>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <span className={`badge text-capitalized ${data?.status === "succeeded" ? "bg-success" : "bg-danger"}`}>{data?.status}</span>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />

                                    </div>


                                </> :
                                <div className='text-center py-5'>
                                    <h3>No result found</h3>
                                </div>
                            }
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Body className='py-4'>
                            <div className='text-center mb-4'>
                                <h4> Are you sure?</h4>
                                <h5>You want to stop this session.</h5>
                            </div>
                            <div className='text-center mt-2'>
                                <button className='btn btn-danger btn-sm me-3' name={sessionCloseId} onClick={confirmClose}> Confirm</button>
                                <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer>  </Modal.Footer> */}
                    </Modal>
                </main>}
        </>
    )
}
