import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons'; 
import Form from 'react-bootstrap/Form'; 
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format'; 
import { useLocation, useNavigate } from 'react-router-dom'; 
import "react-datepicker/dist/react-datepicker.css"; 
// SESSION

const options = [
    { name: "Name", value: "user.fullName", },
    { name: "Email", value: "user.email", },
    { name: "Date", value: "createdAt", }
]
const sortTypes = [
    { name: "Ascending ", value: "asc", },
    { name: "Descending  ", value: "desc", },
]

const filterTypes = [
    { name: "Card", value: "Card" },
    { name: "Wallet", value: "Wallet" }
]
export default function SubscriptionPlan() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [subscriptionlist, setSubscriptionList] = useState({})
    const [sessionCloseId, setsessionCloseId] = useState()
    const [show, setShow] = useState(false);
    const [modelType, setmodelType] = useState("")
    const [planId, setplanId] = useState({
        membership_plan_id: ""
    })
    let limit = 20
    const [params, setParams] = useState({
        sort_key: searchParams.get("sort_key") || "",
        sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
    })

    const [count, setCount] = useState(1)
    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : "",
    })
    const [errors, setErrors] = useState(false)
    const [loadingBtn, setloadingBtn] = useState(false);
    const [newsubscriptionData, setnewsubscriptionData] = useState({
        name: "",
        no_of_months: "",
        total_number_days: "",
        price: "",
    })

    const months = []
    const days = []

    for (let i = 1; i < 29; i++) {
        days.push(<option key={i} value={i}>{i} day{i > 1 ? "s" : ""}</option>)
    }

    for (let i = 0; i <= 12; i++) {
        months.push(<option key={i} value={i}>{i} month{i > 1 ? "s" : ""}</option>)
    }


    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const handleClose = () => {
        setErrors(false)
        setShow(false)
        setnewsubscriptionData({
            name: "",
            no_of_months: "",
            total_number_days: "",
            price: "",
        })
    };
    const handleShow = () => setShow(true);

    const getsubscriptionlist = (param) => {
        Api.GetApi(`${endPoints.GETMEMBERSHIPLIST}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                // console.log("res.data.", res.data.data)



                setSubscriptionList(res.data?.data)
                setloading(false)
            }
        })
    }

    const openEditPlanPop = (data) => {
        setShow(true)
        setmodelType("edit")
        console.log("edit data ", data)
        const updateData = {
            name: data.name,
            no_of_months: data.no_of_months,
            total_number_days: data.days || 0,
            price: data.price,
            membership_plan_id: data._id,
        }

        console.log("update data",  updateData)


        setnewsubscriptionData(updateData)
    }

    const addSubscription = () => {
        setShow(true)
        setmodelType("add")
    }


    const handleSelect = (e) => {
        console.log("dd", e.target.value)

        if (e.target.name === "no_of_months") {
            setnewsubscriptionData({
                ...newsubscriptionData,
                total_number_days: e.target.value == 0 ? 1 : 0,
                [e.target.name]: parseFloat(e.target.value)
            })

        } else {
            setnewsubscriptionData({ ...newsubscriptionData, [e.target.name]: parseFloat(e.target.value) })

        }
    }

    const handleInputchange = (e) => {
        setnewsubscriptionData({ ...newsubscriptionData, [e.target.name]: e.target.value })


    }
    var moment = require('moment-timezone');


    const deleteSubscriptionPlan = () => {

        Api.PostApi(endPoints.DELETEMEMBERSHIPLAN, planId).then(res => {
            // console.log("save", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloadingBtn(false)
            } else {
                // setloadingBtn(false) 
                Api.Alert(res.data.data, "success")
                handleClose()
                setplanId({ membership_plan_id: "" })
                getsubscriptionlist("")
            }
        })

    }
    const openDeletePlanPop = (id) => {
        setShow(true)
        setmodelType("delete")
        setplanId({ membership_plan_id: id })
    }

    const updateSubscription = (data) => {
        Api.PutApi(endPoints.UPDATEMEMBERSHIPLAN, data).then(res => {
            // console.log("save", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloadingBtn(false)
            } else {
                // setloadingBtn(false) 
                Api.Alert(res.data.data, "success")
                handleClose()
                getsubscriptionlist("")

            }
        })
    }
    const saveSubscription = (data) => {
        console.log("d", data)
        Api.PostApi(endPoints.ADDMEMBERSHIPLAN, data).then(res => {
            // console.log("save", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloadingBtn(false)
            } else {
                // setloadingBtn(false) 
                Api.Alert(res.data.data, "success")
                getsubscriptionlist("")
                handleClose()
            }
        })
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (newsubscriptionData.name == "" || newsubscriptionData.price == "") {
            setErrors(true)
        }
        else {

            setErrors(false)
            let data = {}
            data = {
                ...newsubscriptionData
            }
            console.log("before save", newsubscriptionData)
            if (modelType == "edit") {
                updateSubscription(data)
            } else {
                saveSubscription(data)
            }
        }




    }

    useEffect(() => {
        getsubscriptionlist("")
    }, [])

    // console.log("subscription", subscriptionlist)
    // console.log("newsubscriptionData", newsubscriptionData)



    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                        <h1>Subscription Plans</h1>
                        <div><button className="btn btn-danger" onClick={addSubscription}> Add Subscription</button></div>
                    </div>
                    <div className="card">
                        <div className="card-body ">

                            {subscriptionlist && subscriptionlist.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Days</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Action</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subscriptionlist.map((data, index) => {
                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index} >
                                                            <td>    {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                            <td>{data?.name || "---"}</td>
                                                            <td>{data?.days || "---"}</td>
                                                            <td>
                                                                <CurrencyFormat value={parseFloat(data?.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />

                                                            </td>

                                                            <td>
                                                                <button className='btn btn-success btn-sm me-2'
                                                                    onClick={() => openEditPlanPop(data, "edit")} title='Edit partner'
                                                                ><Icon.PencilFill /></button>
                                                                <button className='btn btn-danger btn-sm'
                                                                    onClick={() => openDeletePlanPop(data?._id)} title='Delete plan'
                                                                ><Icon.TrashFill /></button>
                                                            </td>


                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* 
                                    <Pagination>
                                        <Pagination.First onClick={prevPage} disabled={params.page === 1} />
                                        {getPageItems()}
                                        <Pagination.Last
                                            onClick={nextPage}
                                            disabled={params.page === count.length}
                                        />
                                    </Pagination> */}


                                </> :
                                <div className='text-center py-5'>
                                    <h3>No result found</h3>
                                </div>
                            }
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose} centered size={modelType === "delete" ? "md" : "lg"}>
                        <Modal.Body className='py-4'>

                            {modelType === "delete" ?
                                <>
                                    <div className='text-center mb-4'>
                                        <h4> Are you sure?</h4>
                                        <h5>You want to delete this subscription.</h5>
                                    </div>

                                </> : null
                            }

                            {modelType === "edit" ?
                                <>
                                    <div className=' mb-4'>
                                        <h5> Edit Subscription</h5>
                                    </div>
                                    <div className=' mb-4'>
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <Form.Group >
                                                    <Form.Label><strong>Name*</strong></Form.Label>
                                                    <div className='inputWithText'>
                                                        <Form.Control type="text" name="name" placeholder="Plan name"
                                                            onChange={(e) => handleInputchange(e)}
                                                            value={newsubscriptionData?.name}
                                                            isInvalid={errors && newsubscriptionData?.name == ""}
                                                        />
                                                        <span className='inputWithText__text ms-1 bold h6 m-0'>Pass</span>
                                                    </div>
                                                    {errors && newsubscriptionData?.name == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter name</Form.Control.Feedback> : null}
                                                </Form.Group>

                                            </div>
                                            <div className="col-6">
                                                <Form.Group >
                                                    <Form.Label><strong>Price*</strong></Form.Label>
                                                    <Form.Control type="number" name="price" placeholder="Plan price"
                                                        onChange={(e) => handleInputchange(e)}
                                                        value={newsubscriptionData?.price}
                                                        isInvalid={errors && newsubscriptionData?.price == ""}
                                                        min="0"
                                                        step="1" 
                                                    />
                                                    <Form.Control.Feedback type="invalid">Please enter price</Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                            <div className="col-6">
                                                <Form.Group >
                                                    <Form.Label><strong>Months*</strong></Form.Label>
                                                    <Form.Select onChange={(e) => handleSelect(e)} name='no_of_months'
                                                        value={newsubscriptionData.no_of_months} >
                                                        <option value={""} defaultValue disabled>Select months</option>
                                                        {months}
                                                    </Form.Select>
                                                    {errors && newsubscriptionData.no_of_months == null ?
                                                        <div className='invalid-feedback d-block'>Please select no of months</div>
                                                        : null}
                                                </Form.Group>
                                            </div>
                                            {newsubscriptionData.no_of_months == 0 ?
                                                <div className="col-6">
                                                    <Form.Group >
                                                        <Form.Label><strong>days*</strong></Form.Label>
                                                        <Form.Select disabled={newsubscriptionData.no_of_months > 0} onChange={(e) => handleSelect(e)} name='total_number_days'
                                                            value={newsubscriptionData.total_number_days}
                                                        >
                                                            <option value={""} defaultValue disabled>Select days</option>
                                                            {days}
                                                        </Form.Select>
                                                        {errors && newsubscriptionData.total_number_days == "" ?
                                                            <div className='invalid-feedback d-block'>Please select no of days</div>
                                                            : null}
                                                    </Form.Group>
                                                </div> : null
                                            }

                                        </div>
                                    </div>

                                </> : null
                            }
                            {modelType === "add" ?
                                <>
                                    <div className=' mb-4'>
                                        <h5> Add New Subscription</h5>
                                    </div>
                                    <div className=' mb-4'>
                                        <div className="row g-3">
                                            <div className="col-sm-6">
                                                <Form.Group >
                                                    <Form.Label><strong>Name*</strong></Form.Label>
                                                    <div className='inputWithText'>
                                                        <Form.Control   type="text" name="name" placeholder="Plan name"
                                                            onChange={(e) => handleInputchange(e)}
                                                            value={newsubscriptionData?.name}
                                                            isInvalid={errors && newsubscriptionData?.name == ""}
                                                        />
                                                        <span className='inputWithText__text ms-1 bold h6 m-0'>Pass</span>
                                                    </div>
                                                    {errors && newsubscriptionData?.name == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter name</Form.Control.Feedback> : null}
                                                </Form.Group>

                                            </div>
                                            <div className="col-sm-6">
                                                <Form.Group >
                                                    <Form.Label><strong>Price*</strong></Form.Label>
                                                    <Form.Control type="number" name="price" placeholder="Plan price"
                                                        onChange={(e) => handleInputchange(e)}
                                                        min="0"
                                                        step="1" 
                                                        value={newsubscriptionData?.price}
                                                        isInvalid={errors && newsubscriptionData?.price == ""}
                                                    />
                                                    <Form.Control.Feedback type="invalid">Please enter price</Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                            <div className="col-sm-6">
                                                <Form.Group >
                                                    <Form.Label><strong>Months*</strong></Form.Label>
                                                    <Form.Select onChange={(e) => handleSelect(e)} name='no_of_months'
                                                        value={newsubscriptionData.no_of_months} >
                                                        <option value={""} defaultValue disabled>Select months</option>
                                                        {months}
                                                    </Form.Select>
                                                    {errors && newsubscriptionData.no_of_months == null ?
                                                        <div className='invalid-feedback d-block'>Please select no of months</div>
                                                        : null}
                                                </Form.Group>
                                            </div>
                                            { newsubscriptionData.no_of_months === 0 ?
                                                <div className="col-sm-6">
                                                    <Form.Group >
                                                        <Form.Label><strong>Days*</strong></Form.Label>
                                                        <Form.Select disabled={newsubscriptionData.no_of_months > 0} onChange={(e) => handleSelect(e)} name='total_number_days'
                                                            value={newsubscriptionData.total_number_days}
                                                        >
                                                            <option value={""} defaultValue disabled>Select days</option>
                                                            {days}
                                                        </Form.Select>
                                                        {errors && newsubscriptionData.total_number_days == "" ?
                                                            <div className='invalid-feedback d-block'>Please select no of days</div>
                                                            : null}
                                                    </Form.Group>
                                                </div> : null
                                            }

                                        </div>
                                    </div>

                                </> : null
                            }

                            <div className={modelType === "delete" ? "text-center mt-2" : "mt-2"}>
                                {modelType === "delete" ?

                                    <button className='btn btn-danger   me-3' name={sessionCloseId} onClick={deleteSubscriptionPlan}> Confirm</button>
                                    :
                                    <button className='btn btn-primary  me-2' onClick={submitForm}> Submit</button>
                                }
                                <button className='btn btn-outline-danger ' onClick={handleClose} > Cancel</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </main >}
        </>
    )
}

