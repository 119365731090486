import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addYears } from "date-fns";
import axios from 'axios';
import { formatDate } from '../../../../Config/formatDate';

import ResponsivePagination from 'react-responsive-pagination';
import { energyfunction } from '../../../../Config/energyFunction';
import formatRelativeWithOptions from 'date-fns/esm/fp/formatRelativeWithOptions';
// import 'react-responsive-pagination/themes/classic.css';

// SESSION

const options = [
    { name: "Name", value: "name", },
    { name: "Station name", value: "chargestation_name", },
    { name: "Time", value: "createdAt", }
]
const sortTypes = [
    { name: "Ascending ", value: "asc", },
    { name: "Descending  ", value: "desc", },
]

const filterTypes = [
    { name: "Card", value: "Card" },
    { name: "Wallet", value: "Wallet" }
]
const options2 = [
    { name: "Paid", value: "Paid", },
    { name: "Unpaid", value: "Unpaid", },
    { name: "Cancelled", value: "Cancelled", },
    { name: "Refunded", value: "Refunded", }
]

export default function Sessions() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [activeSession, setActiveSession] = useState({})
    const [sessionCloseId, setsessionCloseId] = useState()
    const [show, setShow] = useState(false);
    const [showFilterBtn, setshowFilterBtn] = useState(false);
    let limit = 20
    const [params, setParams] = useState({
        sort_key: searchParams.get("sort_key") || "",
        sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        payment_method: searchParams.get("payment_method") || "",
        status: searchParams.get("status") || "",
        page: searchParams.get("page") || 1,
    })
    const [search, setsearch] = useState(searchParams.get("search") || "")
    const [count, setCount] = useState(1)
    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : "",
    })
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const getactiveSession = (param) => {
        Api.GetApi(`${endPoints.ADMIN_SESSION}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                // console.log("res.data.", res.data.data)

                let arr = []
                res.data.data.length > 0 && res.data.data[0].result.length > 0 && res.data.data[0].result.map((opt) => {
                    // var a = moment(opt?.id?.duration?.startTime);
                    // var b = moment(opt?.id?.duration?.endTime);
                    // var duration = moment.duration(b.diff(a));
                    // duration.hours()
                    // duration.minutes()
                    // duration.seconds()
                    // let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                    // ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""}       ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`

                    // arr.push({ ...opt, duration: duration1 })

                    var milliseconds = moment(opt?._id?.duration?.endTime, 'YYYY-MM-DD hh:mm:ss A').diff(moment(opt?._id?.duration?.startTime, 'YYYY-MM-DD hh:mm:ss A'));

                    let seconds = parseInt(Math.round(((milliseconds / 1000) % 60)));
                    let minutes = parseInt((((milliseconds / (1000 * 60)) % 60)));
                    let hours = parseInt((((milliseconds / (1000 * 60 * 60)) % 24)));
                    // console.log("hours", hours , "minutes",  minutes,   "seconds", seconds )
                    // console.log("duration1", duration1 )
                    // console.log("seconds", seconds)
                    // console.log("minutes", minutes)
                    // console.log("hours", hours)

                    let duration = ` ${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""} ${seconds > 0 ? `${seconds}s` : ""}`

                    // console.log("duration3",duration3)
                    arr.push({ ...opt, duration: duration })
                })
                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }

                setActiveSession(arr)
                setloading(false)
            }
        })
    }


    const clearFilter = () => {
        setshowFilterBtn(false)
        setParams({
            ...params,
            sort_key: "",
            sort_value: "asc",
            payment_method: "",
            status: "",
        })
        deleteparam("sort_key")
        deleteparam("sort_value")
        deleteparam("payment_method")
        deleteparam("status")
    }
    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        deleteparam("search")
    }


    const [dateError, setdateError] = useState(false)
    const filterDateChange = () => {
        if (filterDate?.from == "" || filterDate?.to == "") {
            setdateError(true)
        } else {
            setdateError(false)

            setParams({ ...params, "page": 1, "from": moment(filterDate?.from).format("YYYY-MM-DD"), "to": moment(filterDate?.to).format("YYYY-MM-DD") })
            setparam("from", moment(filterDate?.from).format("YYYY-MM-DD"))
            setparam("to", moment(filterDate?.to).format("YYYY-MM-DD"))
            setparam("page", 1)

        }
    }
    const onDateChange = (date, name) => {
        if (name === "from") {
            setfilterDate({ ...filterDate, [name]: date, to: "" })
        } else {
            setfilterDate({ ...filterDate, [name]: date })
        }
    }
    const clearDate = () => {
        setfilterDate({ from: "", to: "" });
        setParams({ ...params, from: "", to: "", page: 1 });
        // setparam("page", 1)
        // deleteparam("page")
        deleteparam("from")
        deleteparam("to")
    };

    const searchUser = (e) => {
        deleteparam("page")
        setsearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, page: 1, search: "" })
            deleteparam("search")
        } else {
            setparam([e.target.name], e.target.value)
        }
    }

    const onSelectChange = (e) => {
        setshowFilterBtn(true)
        setParams({ ...params, [e.target.name]: e.target.value })
        if (e.target.name == "sort_value") {
            if (params.sort_key != "") {
                setparam("sort_value", e.target.value)
            } else {
                deleteparam("sort_value")
            }
        } else if (e.target.name == "payment_method") {
            setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
            setparam([e.target.name], e.target.value)
            setparam("page", 1)
        } else if (e.target.name == "status") {
            setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
            setparam([e.target.name], e.target.value)
            setparam("page", 1)
        }
        else {
            if (e.target.name == "sort_key") {
                setparam([e.target.name], e.target.value)
                setparam("sort_value", params.sort_value)
            }
            else {
                setparam([e.target.name], e.target.value)
            }
        }
        // DashData(`?sort_key=${params.sort_key}&sort_value=${params.sort_value}&page=${params.page}`)
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (params.search != "") {
                setparam('search', params.search)
            }
            // DashData(`?search=${params.search}`)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [params.search])

    const updateBlockStat = (data) => {
        // STOP_SESSION
        // console.log(data)
        setsessionCloseId(data)
        handleShow()
    }
    const confirmClose = (e) => {
        stopSessionFun(e.target.name)
    }

    const stopSessionFun = (id) => {
        // console.log("sds", id)

        Api.PutApi(`${endPoints.STOP_SESSION}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                handleClose()
                Api.Alert("Session stoped successfully", "success")
                setloading(true)
                setTimeout(() => {
                    getactiveSession(location.search)
                }, 2000);
            }
        })

    }
    // const maxVisiblePages = 5;
    // const getPageItems = () => {
    //     const pageItems = [];
    //     const totalPages = count.length;

    //     for (let number = 1; number <= totalPages; number++) {
    //         if (
    //             number === 1 ||
    //             number === totalPages ||
    //             (number >= params.page - Math.floor(maxVisiblePages / 2) &&
    //                 number <= params.page + Math.floor(maxVisiblePages / 2))
    //         ) {
    //             pageItems.push(
    //                 <Pagination.Item
    //                     key={number}
    //                     active={number == params.page}
    //                     onClick={() => pageChange(number)}
    //                 >
    //                     {number}
    //                 </Pagination.Item>
    //             );
    //         } else if (
    //             (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
    //             (number === totalPages - 1 &&
    //                 params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
    //         ) {
    //             // Display ellipsis for the second and second-to-last pages
    //             pageItems.push(<Pagination.Ellipsis key={number} />);
    //         }
    //     }

    //     return pageItems;
    // };
    // -----*---*---*-----*-----*-----*
    // const prevPage = () => {
    //     setloading(true)
    //     setParams({ ...params, page: params.page - 1 })
    //     setparam("page", params.page - 1)
    // }
    // const nextPage = () => {
    //     setloading(true)
    //     setParams({ ...params, page: params.page + 1 })
    //     setparam("page", params.page + 1)
    // }
    // const pageChange = (number) => {
    //     if (number !== parseInt(params.page)) {
    //         setParams({ ...params, page: number })
    //         setparam("page", number)
    //         setloading(true)
    //     }
    // }


    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }

    const handleExport = () => {
        axios({
            method: 'get',
            url: `${Api.appBaseURL}${endPoints.ADMIN_SESSION}/csv${location.search}`,
            responseType: 'blob',
            headers: {
                "token": Api.isLogIn().token,
            }
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {
                type: 'text/csv'
            });

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `charge_station${filterDate?.from && filterDate?.to ? `_${moment(filterDate?.from).format("YYYY-MM-DD")}_to_${moment(filterDate?.to).format("YYYY-MM-DD")}` : `_${moment(new Date()).format("YYYY-MM-DD")}`}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }).catch((err) => {
            Api.Alert(err, "error")
        });
    }
    var moment = require('moment-timezone');

    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])



    useEffect(() => {
        if (!searchParams.get("from") && !searchParams.get("to")) {
            clearDate()
        }
        if (!searchParams.get("search")) {
            clearSearch()
            setsearch("")
        }
        if (searchParams.get("payment_method") || searchParams.get("status") || searchParams.get("sort_key")) {
            setshowFilterBtn(true)
        } else {
            setshowFilterBtn(false)
            clearFilter()

        }
        if (searchParams.get("page") && searchParams.get("page") != params.page) {
            setParams({ ...params, page: searchParams.get("page") })
            console.log("page param missing")
            console.log("param: ", params.page)
        }

        getactiveSession(location.search)
    }, [location.search])
    console.log("searchParams.get(page)", searchParams.get("page"))
    console.log("params.page", params.page)
    console.log("activeSession", activeSession)

    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                        <h1>Sessions</h1>
                        {activeSession && activeSession.length > 0 ? <Button variant="primary" size='sm' className='me-2 align-items-center' onClick={handleExport} >
                            Export CSV   </Button> : null}
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow userdetailTopRow--sessionpage">

                                <div className='userdetailTopRow__left  '>
                                    <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row" >
                                        <div className="search-bar pe-1  ">
                                            <div className="search-form d-flex align-items-center" >
                                                <input type="text" className='form-control' name="search"
                                                    placeholder="Search by session id/station/partner/user"

                                                    value={search} title="Enter search keyword" onChange={searchUser} />
                                                <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                            </div>
                                        </div>
                                        {searchParams.get("search") && searchParams.get("search") !== "" ?
                                            <button type="button" className="textClear nowrap " onClick={clearSearch}>Clear Search</button> : null
                                        }
                                    </div>
                                    <div className='dateFilterBox flex-wrap  d-flex align-items-start  me-md-3'>
                                        <div className="dateFilterBox__col me-2 mb-1">
                                            <DatePicker
                                                className="reportingSelectBox"
                                                selected={filterDate.from}
                                                onChange={(date) => onDateChange(date, "from")}
                                                selectsStart
                                                startDate={filterDate.from}
                                                endDate={filterDate.to}
                                                placeholderText="Start Date"
                                                maxDate={new Date()}
                                            />
                                            <div className='error small'>
                                                {dateError && filterDate?.from == "" ? "Please choose start date " : null}
                                            </div>
                                        </div>
                                        <div className="dateFilterBox__col me-2 mb-1">
                                            <DatePicker
                                                className="reportingSelectBox"
                                                selected={filterDate.to}
                                                onChange={(date) => onDateChange(date, "to")}
                                                selectsEnd
                                                startDate={filterDate.from}
                                                endDate={filterDate.to}
                                                minDate={filterDate.from}
                                                placeholderText="End Date"
                                                maxDate={addYears(new Date(filterDate?.from), 1) < new Date() ? addYears(new Date(filterDate?.from), 1) : new Date()}
                                            />
                                            <div className='error small'>
                                                {dateError && filterDate?.to == "" ? "Please choose end date " : null}
                                            </div>
                                        </div>
                                        <div className="dateFilterBox__btncol">
                                            <button className='btn btn-primary btn-sm' onClick={filterDateChange}>Filter</button>
                                            {filterDate.to !== "" || filterDate.from !== ""
                                                ? <button type="button" className="textClear" onClick={clearDate}>Clear Date</button> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-md-flex align-items-center userdetailTopRow__right userdetailTopRow__right--sessionpage" >
                                    <div className='my-2 me-2 d-flex flex-wrap'>
                                        <div className='my-1 me-2'>
                                            <div className='sortingSelect_outer me-1'>
                                                <select className='sortingSelect' name='status' value={params.status} onChange={onSelectChange}>
                                                    <option disabled defaultValue value="">Payment Status </option>
                                                    {options2.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.value}>{data.name}</option>
                                                        )
                                                    })

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='my-1 me-2'>

                                            <select className='sortingSelect' name='payment_method' value={params.payment_method} onChange={onSelectChange}>
                                                <option disabled defaultValue value="">Filter By</option>
                                                {filterTypes.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.name}</option>
                                                    )
                                                })}
                                            </select>

                                        </div>
                                    </div>
                                    <div className='my-2  d-flex flex-wrap'>


                                        <div className='my-1 me-2'>
                                            <select className='sortingSelect' name='sort_key' value={params.sort_key} onChange={onSelectChange}>
                                                <option disabled defaultValue value="">Sort By</option>
                                                {options.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {searchParams.get("sort_key") && searchParams.get("sort_key") !== "" ?
                                            <div className='my-1 '>
                                                <select className='sortingSelect' name='sort_value' value={params.sort_value} onChange={onSelectChange}>

                                                    {sortTypes.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.value}>{data.name}</option>
                                                        )
                                                    })}
                                                </select> </div> : null}
                                        {/* {searchParams.get("sort_key") && searchParams.get("sort_key") !== "" ?
                                            <div className='my-1 ms-2'>
                                                <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> </div> : null} */}
                                    </div>
                                    <div className='my-1'>  {showFilterBtn ?
                                        <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null}
                                    </div>
                                </div>
                            </div>
                            {activeSession && activeSession.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th className='sorting' scope="col">Session Id</th>
                                                    <th className='sorting' scope="col">Station</th>
                                                    <th scope="col">Partner</th>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Start Time</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Consumption<small>(kWh)</small></th>
                                                    <th scope="col">Cost<small>(MYR)</small> </th>
                                                    <th scope="col">Payment Status</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeSession.length > 0 && activeSession.map((data, index) => {
                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index} >
                                                            <td>   {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                            <td>{data?._id?._id}</td>
                                                            <td>{data?._id?.chargestation_name}</td>
                                                            <td>{data?._id?.partner ? data?._id?.partner : "--"}</td>
                                                            <td className='text-capitalize fixedWidth'>
                                                                {data?._id?.user_id ? 
                                                                <Link to={`/users/${data?._id?.user_id}`}>{data?._id?.first_name} {data?._id?.last_name}</Link>
                                                                :<span className='textClear fw-normal'>User Deleted</span>                                                                
                                                            }
                                                                
                                                                </td>
                                                            {/* <td>{moment(data?._id?.duration?.startTime).tz("Asia/kuala_lumpur").format("lll")}</td> */}
                                                            {/* <td>{moment(new Date(data?._id?.duration?.startTime)).format("lll")}</td> */}
                                                            <td>{formatDate(data?._id?.duration?.startTime)}</td>
                                                            <td>{data?.duration}</td>
                                                            <td>{energyfunction(data?._id?.consumption)}</td>
                                                            <td>{data?._id?.cost ? <CurrencyFormat value={parseFloat(data?._id?.cost).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /> : <CurrencyFormat value={parseFloat(0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</td>
                                                            <td><span className={`badge ${data?._id?.payment == "Paid" ? "bg-success" : "bg-danger"}`}>{data?._id?.payment}</span></td>
                                                            <td>{data?._id?.payment_method || "--"} </td>
                                                            <td>{data?._id?.status} </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <Pagination>
                                        <Pagination.First onClick={prevPage} disabled={params.page == 1 ? true : false} />
                                        {count.length > 0 && count.map((number) => {
                                            return ( 
                                                <Pagination.Item onClick={() => pageChange(number)} key={number} active={number == params.page}>
                                                    {number}
                                                </Pagination.Item>  
                                            )
                                        })}
                                        <Pagination.Last onClick={nextPage} disabled={params.page == count.length ? true : false} />
                                    </Pagination> */}

                                    {/* <Pagination>
                                        <Pagination.First onClick={prevPage} disabled={params.page === 1} />
                                        {getPageItems()}
                                        <Pagination.Last
                                            onClick={nextPage}
                                            disabled={params.page === count.length}
                                        />
                                    </Pagination> */}
                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />

                                    </div>
                                </> :
                                <div className='text-center py-5'>
                                    <h3>No result found</h3>
                                </div>
                            }
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose} centered>

                        <Modal.Body className='py-4'>
                            <div className='text-center mb-4'>
                                <h4> Are you sure?</h4>
                                <h5>You want to stop this session.</h5>
                            </div>

                            <div className='text-center mt-2'>
                                <button className='btn btn-danger btn-sm me-3' name={sessionCloseId} onClick={confirmClose}> Confirm</button>
                                <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>

                  
                </Modal.Footer> */}
                    </Modal>
                </main>}
        </>
    )
}
