import React from 'react'
import Index from '../../../../Assets/Images/Index'
import _ from "underscore";
// SESSION

export default function QRPDF(props) {
    const style = {
        pdf: {
            height: "100%",
            width: "575px",
            backgroundColor: "#fff",
            color: "#000",
            margin: "10px",
            padding: "20px",
            fontSize: "10px",
            fontFamily: "Arial",
            textAlign: "center"
        },
        header: {
            display: "table",
            width: "100%"
        },
        header_row: {
            display: "table-row",
        },
        header_col: {
            display: "table-cell",
            whiteSpace: "nowrap",
        },
        header_left: {
            display: "table-cell",
            whiteSpace: "nowrap",
            width: "50%"
        },
        header_right: {
            display: "table-cell",
            whiteSpace: "nowrap",
            width: "50%"
        },
        ref: {
            fontSize: "18px",
            fontFamily: "Arial"
        },
        ref2: {
            fontSize: "14px",
            fontFamily: "Arial",
            marginBottom: "0"
        },
        qr_code_img: {
            border: "#ddd 1px solid",
            borderRadius: "10px"
        },
        qr_code_img_outer: {
            padding: "5px",
            margin: "20px 0 10px"
        }
    }
    return (
        <div className='text-center' style={style.pdf}>
            <div style={style.header}>
                <div style={style.header_row}>
                    <div style={style.header_col}>
                    </div>
                    <div style={style.header_col}>
                        <div className='qr_logo'>
                            <img src={Index?.logo} alt="" width={200} />
                        </div>
                        <div style={style.qr_code_img_outer}>
                            <img style={style.qr_code_img} src={props.data?.qrcode?.Qrcode} alt="" width={200} />
                        </div>
                        <p style={style.ref}>Charger ID: {props.data?.qrcode?.reference_id}</p>
                    </div>
                </div>
                <div style={style.header_col}>
                </div>
            </div>
        </div>
    )
}
