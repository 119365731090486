export default {
    LOGIN: "admin/login",

    DASH: "admin/dashboard",
    GET_PAYMENT_GATEWAY: "admin/dashboard/paymentGateway",
    MAINTAINANCE_MODE: "admin/dashboard/maintenanceMode",
    MAINTAINANCE_LOG: "admin/dashboard/maintenance/log",

    USERLIST: "admin/users/list",
    USER_TRANSACTION: "admin/users/wallet/transactions",
    USER_REFUND: "admin/users/wallet/refund",
    UPDATEBLOCKSTAT: "admin/users/status",
    USER_SESSION: "admin/users/sessions",
    USER_DETAIL: "/admin/users/listId",
    USER_SESSION_REFUND: "admin/users/session/refund",
    EDIT_USER: "admin/users/edit",

    ADMIN: "admin",
    SESSION: "admin/session/active",
    ADMIN_SESSION: "admin/session",
    STOP_SESSION: "admin/session/stop",
    MARKCANCELSESION: "admin/session/markcanceld",

    GETWALLETTRANS: "admin/users/list/wallet",

    TOPUPLIST: "admin/wallet/topup",

    CHARGING_STATION: "admin/chargingStation/list",
    UNASSIGN_CHARGER_LIST: "admin/chargingstation/list/unassigned",
    UPLOADLOGO: "admin/chargingstation/location/upload-image",
    REMOVE_LOGO: "admin/chargingstation/location/remove-image",
    // 
    UPDATELOCATIONSTATUS: "admin/chargingstation/location/update-status",

    PARTNER_LIST: "admin/partners/list",
    ADD_PARTNER: "admin/partners/register",
    UPDATE_PARTNER: "admin/partners/update",
    CO_PARTNER: "admin/partners",
    UNASSIGN_CHARGER: "admin/partners/unassign/chargers",
    RESEND_VERIFICATION_LINK: "admin/partners/resend/mail",

    UNPAIDSESSION: "admin/session/unpaid",
    MARKPAIDSESSION: "admin/session/payment",

    PRICING: "admin/pricing/",

    NOTIFICATIONLIST: "admin/notification/list",
    NOTIFICATION: "admin/notification",
    NOTIFICATIONSEND: "admin/notification/send",


    GETMEMBERSHIPLIST: "admin/membership/getmembership-plans",
    ADDMEMBERSHIPLAN: "admin/membership/addmembership-plan",
    UPDATEMEMBERSHIPLAN: "admin/membership/updatemembership-plan",
    DELETEMEMBERSHIPLAN: "admin/membership/deletemembership-plan",


    GETBENEFITSLIST: "admin/membership/benefits",
    ADDBENEFIT: "admin/membership/add-benefits",
    UPDATEBENEFIT: "admin/membership/updateMembershipBenefits",
    DELETEBENEFIT: "admin/membership/delete-benefits",

    WALLETACTION: "admin/wallet/wallet-action",


    GETBENIFITDESCRIPTION: "admin/membership/description-list",
    ADDBENIFITDESCRIPTION: "admin/membership/add-description",
    EDITBENIFITDESCRIPTION: "admin/membership/update-description",
    DELETEBENIFITDESCRIPTION: "admin/membership/description",

    CIMBGETBENIFITS: "admin/cimb/benefits",
    CIMBUPDATEBENIFITS: "admin/cimb/update/benefit",
    CIMBCHARGERS: "admin/cimb/chargers",
    CIMBAVAILABLECHARGERS: "admin/cimb/charger/avilable",

    // 
    CIMBADDCHARGERS: "admin/cimb/add/charger",
    CIMBREMOVECHARGERS: "admin/cimb/remove/charger",
    CIMBUPDATECHARGERS: "admin/cimb/update/charger",
    // 

    CIMBADDDESCRIPTION: "admin/cimb/description",
    CIMBUPDATEDESCRIPTION: "admin/cimb/description/update",
    CIMBDELETEDESCRIPTION: "admin/cimb/description/",


    // 
    GETSYSTEMWALLET: "/admin/system/wallet",
    GETWALLETTRANSACTION: "admin/system/wallet/transactions",
    ADDWALLETCREDIT: "admin/system/wallet/add/credits",


    CIMBUSERLIST: "admin/cimb/user/list",
    CIMBADDSERIALNUMBER: "admin/cimb/add/serial-number",
    CIMBADDSERIALNUMBERINBULK: "admin/cimb/add/bulk/serial-number",

    // 
    USERCOUNT: "admin/dashboard/user-count",
    DASHSALESREPORT: "admin/dashboard/sales-report",
    

}

