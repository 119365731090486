import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import _ from "underscore"
import CurrencyFormat from 'react-currency-format';

export default function UserDetail() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const param = useParams()
    const [userDetail, setuserDetail] = useState([])

    const getUserDetail = (id) => {
        Api.GetApi(`${endPoints.USER_DETAIL}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                let arr = []



                setuserDetail(res.data.data?.result?.[0]?._id)

                setloading(false)
            }
        })
    }






    useEffect(() => {
        getUserDetail(param.id)
    }, [])
    console.log("userDetail", userDetail)
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>User Detail</h1>
                    </div>
                    <section className='section profile'>
                        <div className="row profile-overview">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body profile-card pt-4 ">
                                        <div className='mb-3'>
                                            <Link onClick={(e) => { e.preventDefault(); navigate(-1) }} className="card-title"> <Icon.ArrowLeft /> <span className='ms-2 userName'>
                                                Back </span>
                                            </Link>
                                        </div>

                                        <h4></h4>
                                        <h5 className="card-title">Profile Details</h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Name</div>
                                            <div className="col-lg-9 col-md-8">{userDetail?.name}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Email</div>
                                            <div className="col-lg-9 col-md-8">{userDetail?.email} </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Phone</div>
                                            <div className="col-lg-9 col-md-8">{userDetail?.country_code} {userDetail?.phone_number}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Session</div>
                                            <div className="col-lg-9 col-md-8">
                                                <Link to={`/users/session/${userDetail?._id}`} className='d-block'>{userDetail?.session} </Link>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Wallet</div>
                                            <div className="col-lg-9 col-md-8">
                                                <Link to={`/transactions/wallet/${userDetail?._id}`} className='d-block'>
                                                    <CurrencyFormat value={parseFloat(userDetail?.wallet?.balance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>





                </main>}
        </>
    )
}
