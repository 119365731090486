import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addYears } from "date-fns";
import axios from 'axios';
import Index from '../../../../Assets/Images/Index';
import { formatDate } from '../../../../Config/formatDate';
import _ from 'underscore';


const maxdescLength = 300
export default function Benefit() {
    const searchParams = new URLSearchParams(window.location.search)
    const [listLength, setlistLength] = useState({
        sessionLength: 0,
        walletLength: 0,
    })
    const [descLength, setdescLength] = useState(0)
    const [loading, setloading] = useState(true)
    const [BenefitDescriptions, setBenefitDescriptions] = useState()
    const [sessionCloseId, setsessionCloseId] = useState()
    const [show, setShow] = useState(false);
    const [modelType, setmodelType] = useState("")
    const [planId, setplanId] = useState({
        benefit_id: ""
    })
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
    })
    const [editedValues, seteditedValues] = useState({
        benefit_type: "",
        value: 20,
        benefit_id: ""
    })
    const [descEditValues, setdescEditValues] = useState({
        benefit_description: "",
        description_id: ""
    })
    const [errors, setErrors] = useState(false)
    const [loadingBtn, setloadingBtn] = useState(false);
    const [Benefitsdetails, setBenefitsdetails] = useState({
        wallet: [],
        session: []
    })
    const handleClose = () => {
        setErrors(false)
        setShow(false)
        setdescLength(0)
    };
    const handleShow = () => setShow(true);

    const getbenefitslist = (param) => {
        Api.GetApi(`${endPoints.GETBENEFITSLIST}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {

                let wallet = []
                let session = []

                res.data?.data.filter((data) => data.benefit_for === "Wallet").map((data) => {
                    wallet.push(data)
                })

                res.data?.data.filter((data) => data.benefit_for === "Session").map((data) => {
                    session.push(data)
                })

                setlistLength({
                    ...listLength,
                    sessionLength: _.last(session),
                    walletLength: _.last(wallet)
                })

                setBenefitsdetails({
                    ...Benefitsdetails,
                    wallet: wallet,
                    session: session
                })

                setloading(false)
            }
        })
    }

    const getbenefitdescriptionlist = (param) => {
        Api.GetApi(`${endPoints.GETBENIFITDESCRIPTION}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setBenefitDescriptions(res.data?.data)
                setloading(false)
            }
        })
    }
    const openEditPlanPop = (data) => {
        setShow(true)
        setmodelType("edit")
        seteditedValues({
            benefit_type: data.benefit_for,
            value: data.value,
            benefit_id: data._id

        })

        // setBenefitsdetails(updateData)
    }
    const handleEditInputchange = (e) => { 
        seteditedValues({ ...editedValues, [e.target.name]: parseFloat(e.target.value) })

    }

    const handleInputchange = (e, index, type) => { 
        let arr = []
        Benefitsdetails[type].map((x, i) => {
            if (i == index) {
                arr.push({ ...x, [e.target.name]: parseFloat(e.target.value) })
            } else {
                arr.push(x)
            }
        })
       

        setBenefitsdetails({
            ...Benefitsdetails,
            [type]: arr
        })


    }
    const closeAddMore = (name) => {
        let arr
        arr = Benefitsdetails[name]
        arr.pop()
        setBenefitsdetails({ ...Benefitsdetails, [name]: arr })

    }
    const addMore = (name) => { 
        let ss = {
            benefit_for: "Wallet",
            min_value: listLength?.walletLength?.max_value + 1 || 0,
            max_value: "",
            type: "percentage",
            value: "",
            toEdit: true,
        }
        if (name === "Wallet") {
            let arr = Benefitsdetails.wallet
            arr.push(ss)
            setBenefitsdetails({
                ...Benefitsdetails,
                wallet: arr
            })
        } else {
            let arr = Benefitsdetails.session
            arr.push({ ...ss, benefit_for: "Session", min_value: listLength?.sessionLength?.max_value + 1 || 0 })
            setBenefitsdetails({
                ...Benefitsdetails,
                session: arr
            })
        }
    }
    const deleteSubscriptionPlan = () => {
        if (planId.deldesc) {
            Api.DeleteApi(`${endPoints.DELETEBENIFITDESCRIPTION}/${planId.benefit_id}`).then(res => { 
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    // setloadingBtn(false)
                } else {
                    // setloadingBtn(false) 
                    Api.Alert(res.data.data, "success")
                    handleClose()
                    setplanId({ benefit_id: "" })
                    getbenefitdescriptionlist("")
                }
            })
        }
        else {
            Api.PostApi(endPoints.DELETEBENEFIT, planId).then(res => {
                // console.log("save", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    // setloadingBtn(false)
                } else {
                    // setloadingBtn(false) 
                    Api.Alert(res.data.data, "success")
                    handleClose()
                    setplanId({ benefit_id: "" })
                    getbenefitslist("")
                }
            })
        }



    }
    const openDeletePlanPop = (id, type) => {
        setShow(true)
        setmodelType("delete")
        if (type === "description") {

            setplanId({ benefit_id: id, deldesc: true })
        } else {
            setplanId({ benefit_id: id })

        }
    } 
    const addBenifit = (data) => {
        if (data.max_value == "" || data.value === "") {
            setErrors(true)
        } else if (data?.min_value > data?.max_value) {
            setErrors(true)
        } else if (data?.min_value === data?.max_value) {
            setErrors(true)
        }
        else {
            setErrors(false)
            let uptData = {
                benefit_for: data.benefit_for,
                value: data.value,
                min_value: parseFloat(data.min_value) || 0,
                max_value: data.max_value,
            }
            createNewBenifit(uptData)
        }
    }
    const updateSubscription = (data) => {
        console.log("submit form", data)
        Api.PutApi(endPoints.UPDATEBENEFIT, data).then(res => {
          
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloadingBtn(false)
            } else {
                // setloadingBtn(false) 
                Api.Alert(res.data.data, "success")
                handleClose()
                getbenefitslist("")

            }
        })
    }
    const createNewBenifit = (data) => {
        Api.PostApi(endPoints.ADDBENEFIT, data).then(res => { 
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloadingBtn(false)
            } else {
                // setloadingBtn(false) 
                Api.Alert(res.data.data, "success")
                getbenefitslist("")
            }
        })
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (editedValues.benefit == "") {
            setErrors(true)
        }
        else {
            setErrors(false)
            let data = {}
            data = {
                ...editedValues
            }
            updateSubscription(data)

        }

    }
    const submitEditDescForm = (e) => {
        e.preventDefault();
        if (descEditValues.benefit_description == "") {
            setErrors(true)
        }
        else {
            setErrors(false)
            let data = {}
            data = {
                ...descEditValues
            }
            updateEditSubscription(data)

        }

    }
    const openAddDescription = () => {
        // "benefit_description": "benefit is desc ", 
        let ss = {
            benefit_description: "",
            toEdit: true,
        }
        setBenefitDescriptions([...BenefitDescriptions, ss])
    }
    const handleChange = (e, ind) => { 
        let arr = []
        setdescLength(e.target.value.length)
        BenefitDescriptions.map((x, i) => {
            if (i === ind) {
                arr.push({ ...x, [e.target.name]: e.target.value })
            } else {
                arr.push(x)
            }
            setBenefitDescriptions(arr)
        })

    }
    const editBenifitDescription = (data) => {
        setdescEditValues({
            benefit_description: data.benefit_description,
            description_id: data._id
        })
        setShow(true)
        setmodelType("editBenifit")
        setdescLength(descEditValues?.benefit_description.length || 0)
    }

    const handleEditDescriptiochange = (e) => {
        setdescEditValues({ ...descEditValues, [e.target.name]: e.target.value })
        setdescLength(e.target.value.length)

    } 
    console.log("d",descEditValues)
    const addBenifitDescription = (data) => {

        if (data.benefit_description === "") {
            setErrors(true)
            setloadingBtn(false)
        }
        else {
            let updata = {
                benefit_description: data.benefit_description
            }

            Api.PostApi(endPoints.ADDBENIFITDESCRIPTION, updata).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloadingBtn(false)
                } else {
                    setloadingBtn(false)
                    Api.Alert(res.data.data, "success")
                    getbenefitdescriptionlist("")
                    setdescLength(0)
                }
            })


        }

    }
    const updateEditSubscription = (data) => {
        Api.PutApi(endPoints.EDITBENIFITDESCRIPTION, data).then(res => { 
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloadingBtn(false)
            } else {
                // setloadingBtn(false) 
                Api.Alert(res.data.data, "success")
                handleClose()
                getbenefitdescriptionlist("")

            }
        })
    }

    const closeAddDescription = () => {
        let arr = [...BenefitDescriptions]
        arr.pop()
        setBenefitDescriptions(arr)
        setdescLength(0)
    }


    useEffect(() => {
        getbenefitslist("")
        getbenefitdescriptionlist("")
    }, [])
    // console.log("BenefitDescriptions", BenefitDescriptions)
    // console.log("Benefitsdetails.wallet", Benefitsdetails?.wallet)
    // console.log("Benefitsdetails.session", Benefitsdetails?.session)

    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                        <h1>Benefits</h1>
                        <div> </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body ">
                                    <h5 className='card-title pb-0'>Wallet</h5>
                                    {Benefitsdetails.wallet && Benefitsdetails.wallet.length > 0 ?
                                        <>
                                            <div className="table-responsive">
                                                <table className="table dataTable">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Min Amount</th>
                                                            <th scope="col">Max Amount</th>
                                                            <th scope="col">Value</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Benefitsdetails.wallet.map((data, index) => {
                                                            let updInd = index + 1
                                                            return (
                                                                <tr key={index} >
                                                                    <td>    {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                                    <td>
                                                                        {data.toEdit ? <>
                                                                            <Form.Group >
                                                                                <Form.Control type="text" name="min_value" placeholder=""
                                                                                    disabled
                                                                                    value={data?.min_value || 0}
                                                                                />
                                                                            </Form.Group>
                                                                        </> :
                                                                            <CurrencyFormat value={parseFloat(data?.min_value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}
                                                                    </td>
                                                                    <td>
                                                                        {data.toEdit ?
                                                                            <>
                                                                                <Form.Group >
                                                                                    <Form.Control type="number" name="max_value" placeholder="Max amount"
                                                                                        onChange={(e) => handleInputchange(e, index, "wallet")}
                                                                                        value={data?.max_value}
                                                                                        isInvalid={errors && data?.max_value == ""}
                                                                                        min="0"
                                                                                        step="1" 
                                                                                    />
                                                                                    {errors && data?.min_value > data?.max_value || data?.min_value === data?.max_value ? <Form.Control.Feedback type="invalid" className='d-block small'><small>Should be greater than min amount</small> </Form.Control.Feedback> : null}
                                                                                </Form.Group>
                                                                            </>
                                                                            :
                                                                            <CurrencyFormat value={parseFloat(data?.max_value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</td>
                                                                    <td>
                                                                        {data.toEdit ?
                                                                            <>
                                                                                <Form.Group >
                                                                                    <Form.Control type="number" name="value" placeholder="Value"
                                                                                        onChange={(e) => handleInputchange(e, index, "wallet")}
                                                                                        value={data?.value} 
                                                                                        isInvalid={errors && data?.value === ""}
                                                                                        min="0"
                                                                                        step="1" 
                                                                                    />
                                                                                    {errors && data?.value === "" ? <Form.Control.Feedback type="invalid" className='d-block'><small>Please enter value</small></Form.Control.Feedback> : null}
                                                                                </Form.Group>

                                                                            </>
                                                                            :
                                                                            <>{data?.type === "percentage" ? data?.value + "%"
                                                                                : <CurrencyFormat value={parseFloat(data?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        {data.toEdit ?
                                                                            <>
                                                                                <button className='btn btn-success btn-sm me-2' onClick={() => addBenifit(data)}><Icon.PlusLg /> Save</button>
                                                                                <button onClick={() => closeAddMore("wallet")} className='btn btn-danger btn-sm me-2'><Icon.XLg /></button>

                                                                            </> :
                                                                            <>
                                                                                <button className='btn btn-success btn-sm me-2'
                                                                                    onClick={() => openEditPlanPop(data, "edit")} title='Edit partner'
                                                                                ><Icon.PencilFill /></button>
                                                                                {listLength?.walletLength?._id === data?._id ?
                                                                                    <button className='btn btn-danger btn-sm'
                                                                                        onClick={() => openDeletePlanPop(data?._id)} title='Delete plan'
                                                                                    ><Icon.TrashFill /></button> : null
                                                                                }
                                                                            </>
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </> : 
                                        <div className='text-center py-5'>
                                            <h3>No result found</h3> 
                                        </div>
                                    }
                                    {Benefitsdetails.wallet.filter((x) => x.toEdit).length == 0 && Benefitsdetails.wallet.length < 4 ?
                                        <button className='btn btn-sm btn-primary' onClick={() => addMore("Wallet")}>Add New  </button> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body ">
                                    <h5 className='card-title pb-0'>Session</h5>
                                    {Benefitsdetails.session && Benefitsdetails.session.length > 0 ?
                                        <>
                                            <div className="table-responsive">
                                                <table className="table dataTable">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">From</th>
                                                            <th scope="col">To</th>
                                                            <th scope="col">Value</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Benefitsdetails.session.map((data, index) => {
                                                            let updInd = index + 1
                                                            return (
                                                                <tr key={index} >
                                                                    <td>   {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                                    <td>
                                                                        {data.toEdit ? <>
                                                                            <Form.Group >
                                                                                <Form.Control type="text" name="min_value" placeholder=""
                                                                                    // onChange={(e) => handleInputchange(e)}
                                                                                    disabled
                                                                                    value={data?.min_value}
                                                                                // isInvalid={errors && Benefitsdetails?.name == ""}
                                                                                />
                                                                                {/* {errors && Benefitsdetails?.name == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter name</Form.Control.Feedback> : null} */}
                                                                            </Form.Group>
                                                                        </> :
                                                                            <CurrencyFormat value={parseFloat(data?.min_value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}
                                                                    </td>
                                                                    <td>
                                                                        {data.toEdit ?
                                                                            <>
                                                                                <Form.Group >
                                                                                    <Form.Control type="number" name="max_value" placeholder="Max amount"
                                                                                        onChange={(e) => handleInputchange(e, index, "session")}
                                                                                        value={data?.max_value}
                                                                                        isInvalid={errors && data?.max_value == ""}
                                                                                        min="0"
                                                                                        step="1" 
                                                                                    />
                                                                                    {errors && data?.min_value > data?.max_value || data?.min_value === data?.max_value ? <Form.Control.Feedback type="invalid" className='d-block small'><small>Should be greater than min amount</small> </Form.Control.Feedback> : null}
                                                                                </Form.Group>
                                                                            </>
                                                                            :
                                                                            <CurrencyFormat value={parseFloat(data?.max_value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}
                                                                    </td>
                                                                    <td>
                                                                        {data.toEdit ?
                                                                            <>
                                                                                <Form.Group >
                                                                                    <Form.Control type="number" name="value" placeholder="Value"
                                                                                        onChange={(e) => handleInputchange(e, index, "session")}
                                                                                        value={data?.value}
                                                                                        isInvalid={errors && data?.value === ""}
                                                                                        min="0"
                                                                                        step="1" 
                                                                                    />
                                                                                    {errors && data?.value === "" ? <Form.Control.Feedback type="invalid" className='d-block'><small>Please enter value</small></Form.Control.Feedback> : null}
                                                                                </Form.Group>
                                                                            </>
                                                                            :
                                                                            <>{data?.type === "percentage" ? data?.value + "%"
                                                                                : <CurrencyFormat value={parseFloat(data?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {data.toEdit ?
                                                                            <>
                                                                                <button className='btn btn-success btn-sm me-2' onClick={() => addBenifit(data)}><Icon.PlusLg /> Save</button>
                                                                                <button onClick={() => closeAddMore("session")} className='btn btn-danger btn-sm me-2'><Icon.XLg /></button>

                                                                            </> :
                                                                            <>
                                                                                <button className='btn btn-success btn-sm me-2'
                                                                                    onClick={() => openEditPlanPop(data, "edit")} title='Edit '
                                                                                ><Icon.PencilFill /></button>
                                                                                {listLength?.sessionLength?._id === data?._id ?
                                                                                    <button className='btn btn-danger btn-sm'
                                                                                        onClick={() => openDeletePlanPop(data?._id)} title='Delete plan'
                                                                                    ><Icon.TrashFill /></button> : null
                                                                                }
                                                                            </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </> :
                                        <div className='text-center py-5'>
                                            <h3>No result found</h3>
                                        </div>
                                    }
                                    {Benefitsdetails.session.filter((x) => x.toEdit).length == 0 && Benefitsdetails.session.length < 1 ?
                                        <button className='btn btn-sm btn-primary' onClick={() => addMore("Session")}>Add New  </button>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body ">
                                    <h5 className='card-title pb-0'>Benefit Descriptions</h5>
                                    {BenefitDescriptions && BenefitDescriptions.length > 0 ? <>
                                        <div className="table-responsive">
                                            <table className="table dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col" style={{maxWidth: "400px", wordBreak:"break-all",whiteSpace: "pre-wrap"}}>Description</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {BenefitDescriptions.map((data, index) => {
                                                        let updInd = index + 1
                                                        return (
                                                            <tr key={index} >
                                                                <td> {updInd}</td>
                                                                <td style={{maxWidth: "400px", wordBreak:"break-all",whiteSpace: "pre-wrap"}}>

                                                                    {data.toEdit ? <>
                                                                        <Form.Group >
                                                                            <Form.Control className='resize-none' as="textarea" name='benefit_description'
                                                                                maxLength={maxdescLength} rows={4}
                                                                                value={data.benefit_description}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                isInvalid={errors && data?.benefit_description == ""}
                                                                            />
                                                                             <small>{descLength}/{maxdescLength}</small>
                                                                            {errors && data?.benefit_description == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter description</Form.Control.Feedback> : null}
                                                                        </Form.Group>
                                                                    </> : data?.benefit_description}
                                                                </td>
                                                                <td>
                                                                    {data.toEdit ?
                                                                        <>
                                                                            <button disabled={loadingBtn}
                                                                                className={`btn btn-success btn-sm me-2 ${loadingBtn ? "disabled" : ""}`}
                                                                                onClick={() => {
                                                                                    setloadingBtn(true)
                                                                                    addBenifitDescription(data)
                                                                                }
                                                                                }>{loadingBtn ? "Please Wait" : <><Icon.PlusLg /> Save</>}</button>
                                                                            <button onClick={closeAddDescription} className='btn btn-danger btn-sm me-2'><Icon.XLg /></button>
                                                                        </> :
                                                                        <>
                                                                            <button className='btn btn-success btn-sm me-2'
                                                                                onClick={() => editBenifitDescription(data)}
                                                                                title='Edit ' >
                                                                                <Icon.PencilFill />
                                                                            </button>

                                                                            <button className='btn btn-danger btn-sm'
                                                                                onClick={() => openDeletePlanPop(data?._id, "description")}
                                                                                title='Delete plan' >
                                                                                <Icon.TrashFill />
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </> :
                                        <div className='text-center py-5'>
                                            <h3>No result found</h3>
                                        </div>}
                                    {BenefitDescriptions?.filter((x) => x.toEdit).length == 0 && BenefitDescriptions.length < 3 ?
                                        <button className='btn btn-sm btn-primary' onClick={openAddDescription} >Add New  </button> : null}

                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} centered size="md"
                    // size={modelType === "delete" ? "md" : "md"}
                    >
                        <Modal.Body className='py-4'>
                            {modelType === "delete" ?
                                <>
                                    <div className='text-center mb-4'>
                                        <h4> Are you sure?</h4>
                                        <h5>You want to delete this benefit.</h5>
                                    </div>

                                </> : null
                            }
                            {modelType === "edit" ?
                                <>
                                    <div className=' mb-4'>
                                        <h5> Edit Subscription</h5>
                                    </div>
                                    <div className=' mb-4'>
                                        <div className="row g-3">

                                            <div className="col-12">
                                                <Form.Group >
                                                    <Form.Label><strong>Value(%)*</strong></Form.Label>
                                                    <Form.Control type="number" name="value" placeholder="Enter value"
                                                        onChange={(e) => handleEditInputchange(e)}
                                                        value={editedValues.value}
                                                        isInvalid={errors && editedValues?.value === ""}
                                                        min="0"
                                                        step="1" 
                                                    />
                                                    <Form.Control.Feedback type="invalid">Please enter value</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>

                                </> : null
                            }
                            {modelType === "editBenifit" ? <>
                                <div className=' mb-4'>
                                    <h5> Edit Benefit Description</h5>
                                </div>
                                <div className=' mb-4'>
                                    <div className="row g-3">

                                        <div className="col-12">
                                            <Form.Group >
                                                <Form.Label><strong>Description*</strong></Form.Label>


                                                <Form.Control className='resize-none' placeholder="Enter description" as="textarea" name='benefit_description'
                                                    maxLength={maxdescLength} rows={4}
                                                    value={descEditValues?.benefit_description}
                                                    onChange={(e)=>handleEditDescriptiochange(e)}
                                                    isInvalid={errors && descEditValues?.benefit_description == ""}
                                                />
                                                 <small>{descLength}/{maxdescLength}</small>
                                                {errors && descEditValues?.benefit_description == "" ?
                                                    <Form.Control.Feedback type="invalid">Please enter Description</Form.Control.Feedback> :
                                                    null}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>

                            </> : null}

                            <div className={modelType === "delete" ? "text-center mt-2" : "mt-2"}>
                                {modelType === "delete" ?

                                    <button className='btn btn-danger me-3' name={sessionCloseId} onClick={deleteSubscriptionPlan}> Confirm</button>
                                    : modelType === "editBenifit" ? <button className='btn btn-primary  me-2' onClick={submitEditDescForm}> Submit</button> :
                                        <button className='btn btn-primary  me-2' onClick={submitForm}> Submit</button>
                                }
                                <button className='btn btn-outline-danger ' onClick={handleClose} > Cancel</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </main >}
        </>
    )
}

