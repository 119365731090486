import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore";
import CurrencyFormat from 'react-currency-format';
import { Accordion, Modal } from 'react-bootstrap';
import ResponsivePagination from 'react-responsive-pagination';
const options = [
    { name: "Name", value: "name", },
    { name: "Email", value: "email", },
    { name: "Created", value: "createdAt", }
]
const sortTypes = [
    { name: "Ascending ", value: "asc", },
    { name: "Descending  ", value: "desc", },
]
export default function Users() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [userList, setUserList] = useState()
    const [searchProgress, setsearchProgress] = useState(false)
    let limit = 20
    const [params, setParams] = useState({
        // sort_key: searchParams.get("sort_key") || "",
        // sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
    })
    const [count, setCount] = useState(1)
    const [active, setActive] = useState()

    const [showRefundAlert, setshowRefundAlert] = useState(false);
    const [refund, setRefund] = useState({})
    const [activeWallet, setActiveWallet] = useState({})

    const [activeUser, setActiveUser] = useState({});
    const [transactionData, setTransactionData] = useState([]);
    const [show, setShow] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [errors, setErrors] = useState(false)

    const [userProfil, setuserProfil] = useState({
        first_name: "",
        last_name: "",
        email: "",
        id: "",
    })
    const handleClose = () => {
        setErrors(false)
        setShow(false);
        setTransactionData([])
        setInvalidEmail(false)
    }
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const DashData = (param) => {
        Api.GetApi(`${endPoints.USERLIST}${param}`).then(res => {
            setsearchProgress(false)
            setloading(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                setUserList(res.data.data)
                let newCount = Math.ceil(parseInt(res.data.data?.totalCount?.count) / limit)
                if (newCount !== 0) {
                    let items = [];
                    for (let number = 1; number <= newCount; number++) {
                        items.push(number);
                    }
                    setCount(items)
                } else {
                    setCount(1)
                }
            }
        })
    }
    const getTransactionData = (id) => {
        Api.GetApi(`${endPoints.USER_TRANSACTION}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setShow(true)
                setTransactionData(res.data.data)
                clickActive(res.data.data[0], 0)
            }
        })
    }
    const walletRefund = () => {
        Api.PutApi(endPoints.USER_REFUND, { transactionId: refund?.transaction_id }).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                Api.Alert(res.data.data, "success")
                DashData(location.search)
                getTransactionData(refund?.wallet_id)
                setActive()
                dismissRefund()
            }
        })
    }

    const updateBlockStat = (e) => {
        // UPDATEBLOCKSTAT
        Api.PutApi(endPoints.UPDATEBLOCKSTAT, { "_id": e.target.value }).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                DashData(location.search)
                Api.Alert("Updated sucessfully", "success")
            }
        })
    }
    const [search, setsearch] = useState(searchParams.get("search") || "")
    const searchUser = (e) => {
        setsearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("search", e.target.value)
        }
    }
    useEffect(() => {
        // deleteparam("page")
        const delayDebounceFn = setTimeout(() => {
            if (search != "") {
                setsearchProgress(true)
                setParams({ ...params, page: 1, search: search })
                setparam('search', search)
            }

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const onSelectChange = (e) => {
        setParams({ ...params, [e.target.name]: e.target.value })
        if (e.target.name == "sort_value") {
            if (params.sort_key != "") {
                setparam("sort_value", e.target.value)
            } else {
                deleteparam("sort_value")
            }
        } else {
            setparam([e.target.name], e.target.value)
            setparam("sort_value", params.sort_value)
        }
    }
    const onPageChangeFunction = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }

    // useEffect(() => {
    //     setParams({ ...params, page: searchParams.get("page") || 1 })
    // }, [searchParams.get("page")])

    const clearFilter = () => {
        setParams({
            ...params,
            sort_key: "",
            sort_value: "asc",
        })
        deleteparam("sort_key")
        deleteparam("sort_value")
    }
    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        setsearch("")
        deleteparam("search")
        setsearchProgress(true)
    }
    const walletTransaction = (data, id) => {
        setActiveUser(data)
        getTransactionData(id)
    }

    const showRefund = (data) => {
        setshowRefundAlert(true)
        setRefund(data)
    }
    const dismissRefund = () => {
        setshowRefundAlert(false)
        setRefund({})
    }
    const confirmRefund = () => {
        console.log("refund: ", refund)
        walletRefund()
    }
    const clickActive = (data, i) => {
        setActive(i == active ? null : i)
        setActiveWallet(data)
        setshowRefundAlert(false)
    }

    const openPopfun = (type, data) => {
        console.log("data", data)
        setShow(true)

        setuserProfil({
            id: data._id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
        })

    }

    const handleInputchange = (e) => {
        setuserProfil({ ...userProfil, [e.target.name]: e.target.value })
    }
    const handleSubmitUser = (e) => {
        e.preventDefault()
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (userProfil.first_name.trim(" ") === "" || userProfil.last_name.trim(" ") === "" || userProfil.email.trim(" ") === "") {
            setErrors(true)
            setInvalidEmail(false)
        } else if (userProfil.email !== "" && !re.test(userProfil.email)) {
            setErrors(true)
            setInvalidEmail(true)
        } else {
            let data = userProfil

            Api.PutApi(endPoints.EDIT_USER, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    DashData(location.search)
                    Api.Alert("Updated sucessfully", "success")
                    handleClose()
                }
            })

        }
    }


    useEffect(() => {
        // if (!searchParams.get("sort_key")) {
        //     clearFilter()
        // }
        // if (!searchParams.get("search")) {
        //     console.log("search lebnth1 true", searchParams.get("search"))
        //     // clearSearch()
        // }
        // if (searchParams.get("page") && searchParams.get("page") != params.page) {
        //     setParams({ ...params, page: searchParams.get("page") })
        // }
        console.log("searchParams.get", searchParams.get("sort_key"))
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        DashData(location.search)
    }, [params])

    console.log("search lebnth1", searchParams.get("search"))


    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Users  {userList?.totalCount?.count ? `(${userList?.totalCount?.count})` : null}</h1>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="card">
                    <div className="card-body ">
                        <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow ">
                            {/* <div className="search-bar  mb-3 mb-md-0  userdetailTopRow__left">
                                <div className="search-form d-flex align-items-center" >
                                    <input type="text" className='form-control'
                                        name="search" placeholder="Search by name/email" value={search} title="Enter search keyword" onChange={searchUser} />
                                    <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                </div>
                                {search !== "" ?
                                    <button type="button" className="textClear nowrap " onClick={clearSearch}>Clear Search</button> : null
                                }
                            </div> */}
                            <div className='userdetailTopRow__left col-12 col-md-5'>
                                <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row  " >
                                    <div className="search-bar pe-1  ">
                                        <div className="search-form d-flex align-items-center" >
                                            <input type="text" className='form-control'
                                                name="search" placeholder="Search by name/email/phone" value={search} title="Enter search keyword" onChange={searchUser} autoComplete="off" disabled={searchProgress} />
                                            <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                        </div>
                                    </div>
                                    {search !== "" ?
                                        <button type="button" disabled={searchProgress} className="textClear nowrap " style={{ opacity: searchProgress ? "0" : "1" }} onClick={clearSearch}>Clear Search</button> : null
                                    }
                                </div>
                            </div>

                            <div className=" d-flex align-items-center userdetailTopRow__right" >
                                <select className='sortingSelect' name='sort_key' value={params.sort_key} onChange={onSelectChange}>
                                    <option disabled defaultValue value="">Sort By</option>
                                    {options.map((data, index) => {
                                        return (
                                            <option key={index} value={data.value}>{data.name}</option>
                                        )
                                    })}
                                </select>
                                {searchParams.get("sort_key") && searchParams.get("sort_key") !== "" ?
                                    <select className='sortingSelect' name='sort_value' value={params.sort_value} onChange={onSelectChange}>

                                        {sortTypes.map((data, index) => {
                                            return (
                                                <option key={index} value={data.value}>{data.name}</option>
                                            )
                                        })}
                                    </select> : null}
                                {searchParams.get("sort_key") && searchParams.get("sort_key") !== "" ?
                                    <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null}
                            </div>
                        </div>
                        {userList?.result && userList?.result.length > 0 ?
                            <>
                                <div className="table-responsive position-relative">
                                    <table className="table dataTable">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th className='sorting' scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Created </th>
                                                <th scope="col">Sessions</th>
                                                <th scope="col">Wallet Balance</th>
                                                <th scope="col">Active/Blocked</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        {searchProgress ? <>
                                            <tbody>
                                                <tr>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                </tr>
                                                <tr>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                    <td className='loadingTd'><div className='bar'></div></td>
                                                </tr>

                                            </tbody>
                                        </> :
                                            <tbody>
                                                {userList?.result.map((data, indx) => {
                                                    let updInd = indx + 1
                                                    return (
                                                        <tr key={indx}>
                                                            <th scope="row">
                                                                {/* {params.page > 1 && params.page}{updInd} */}
                                                                {((searchParams.get("page") || 1) - 1) * 20 + updInd}
                                                            </th>
                                                            <td>{data?._id?.first_name || "---"}&nbsp;{data?._id?.last_name || "---"} </td>
                                                            <td>{data?._id?.email || "---"}</td>
                                                            <td>{data?._id?.country_code} {data?._id?.phone_number || "---"}</td>
                                                            <td>{moment(data?._id?.createdAt).format("lll")}</td>
                                                            <td>{data?._id?.session > 0 ? <Link className='d-block' to={`/users/session/${data?._id?._id}`}>{data?._id?.session} </Link> : data?._id?.session}</td>
                                                            <td>
                                                                {/* {data?._id?.wallet && data?._id?.wallet?.balance ?
                                                                <Link to={`/users/wallet/${data?._id?._id}`} className='d-block'
                                                                //  onClick={() => walletTransaction(data, data?._id?.wallet?._id)}
                                                                 >
                                                                    <CurrencyFormat value={parseFloat(data?._id?.wallet?.balance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                                </Link>
                                                                :
                                                                <CurrencyFormat value={parseFloat(0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />

                                                                
                                                                
                                                            } */}
                                                                {
                                                                    <Link to={`/transactions/wallet/${data?._id?._id}`} className='d-block'>
                                                                        {data?._id?.wallet ?

                                                                            <CurrencyFormat value={parseFloat(data?._id?.wallet?.balance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /> :
                                                                            <CurrencyFormat value={parseFloat("0").toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                                        }


                                                                    </Link>
                                                                }
                                                            </td>

                                                            <td> <Form>
                                                                <Form.Check // prettier-ignore
                                                                    type="switch"
                                                                    id={data?._id?._id}
                                                                    label={data?._id?.active ? "Active" : "Blocked"}
                                                                    checked={data?._id?.active}
                                                                    value={data?._id?._id}
                                                                    onChange={updateBlockStat}
                                                                />

                                                            </Form></td>
                                                            <td> <button className='btn btn-sm btn-text btn-outline-primary' title='Edit User' onClick={() => openPopfun("editPop", data?._id)}> <Icon.Pencil className=' me-1' /> </button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        }
                                    </table>

                                </div>
                                <div style={{ maxWidth: "500px" }}>
                                    {searchProgress ?
                                        <div className='loadingTd' style={{ maxWidth: "100px" }}><div className='bar'></div>
                                        </div> :
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={onPageChangeFunction}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />
                                    }
                                </div>
                            </>
                            :
                            <div className='text-center py-5'>
                                <h3>No result found</h3>
                            </div>
                        }
                    </div>

                    <Modal show={show} onHide={handleClose} size='md'>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h6 className='mb-0'>
                                    Edit User
                                    {/* Wallet transactions ({activeUser && `${activeUser?._id?.first_name} ${activeUser?._id?.last_name || ""}`}) <span className='ms-4'>Wallet ID: {activeUser?._id?.wallet?._id}</span> */}
                                </h6>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {/* <div className='row'>
                                <div className='col-sm-4'>
                                    <div className='wallet_accordion_outer'>
                                        {transactionData.length > 0 ?
                                            <>
                                                {transactionData.map((data, i) => {
                                                    return (
                                                        <button title={
                                                            data?.payment_type === "refund" ? "Refund" :
                                                                data?.payment_type === "debit" ? "Debit" :
                                                                    "Credit"
                                                        }
                                                            onClick={() => clickActive(data, i)} key={i} className="w-100 wallet_accordion">
                                                            <div className={`alert fade show d-flex justify-content-between mb-0 w-100 
                                                            ${active == i && data?.payment_type === "refund" ? "alert-secondary  " :
                                                                    active == i && data?.payment_type === "debit" ? "alert-danger" :
                                                                        active == i && data?.payment_type === "credit" ? "alert-success" : ""}`}
                                                                role="alert">
                                                                <p>
                                                                    <small> {data?.transaction_id || ""}</small>
                                                                </p>
                                                                <div className='d-flex align-items-center'> 

                                                                    <strong
                                                                        className={
                                                                            data?.payment_type === "refund" ? "badge badge-small  bg-secondary " :
                                                                                data?.payment_type === "debit" ? "badge badge-small bg-danger" :
                                                                                    "badge badge-small bg-success"
                                                                        }

                                                                    >
                                                                        <CurrencyFormat value={parseFloat(data?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                                    </strong> 
                                                                    <img className='ms-2 d-block' src={
                                                                        data?.payment_type === "refund" ? Index.refunded :
                                                                            data?.payment_type === "debit" ? Index.debited :
                                                                                Index.credited} width="15" />
                                                                </div>
                                                            </div>
                                                        </button>
                                                    )
                                                })}
                                            </>
                                            :
                                            <h6 className='p-5 text-center'>No result found!</h6>
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-8'>
                                    {!_.isEmpty(activeWallet) ?
                                        <div className='wallet_accordion_panel'>
                                            <div className="alert fade show d-flex justify-content-between mb-0 w-100" role="alert">
                                                <p>{moment(activeWallet?.createdAt).format("lll")}</p>
                                                <strong>
                                                    <CurrencyFormat value={parseFloat(activeWallet?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></strong>
                                            </div>
                                            <hr className='m-0' />
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item d-flex justify-content-between"><div><div>Transaction ID</div></div> <div>{activeWallet?.transaction_id || ""}</div></li>
                                                <li className="list-group-item d-flex justify-content-between"><div><div>Payment type</div></div> <div style={{ textTransform: "capitalize" }}>
                                                    {activeWallet?.payment_type !== "refund" ? activeWallet?.payment_type : null}
                                                    {!activeWallet?.is_refund && activeWallet?.payment_type == "debit" ?
                                                        <button className='btn btn-outline-primary btn-sm ms-3' onClick={() => showRefund(activeWallet)}>Refund</button>
                                                        : activeWallet?.is_refund || activeWallet?.payment_type == "refund" ?
                                                            <strong className='ms-3'>Refunded</strong>
                                                            :
                                                            null
                                                    }
                                                </div></li>
                                                {showRefundAlert ?
                                                    <li className="list-group-item text-center p-3">
                                                        <p className='mb-0'><strong>Are you sure?</strong></p>
                                                        <p>You want to refund this transaction.</p>
                                                        <div className='mt-2'>
                                                            <button className='btn btn-sm btn-primary' onClick={confirmRefund}>Confirm</button>
                                                            <button className='btn btn-sm btn-outline-danger ms-3' onClick={dismissRefund}>Dismiss</button>
                                                        </div>
                                                    </li>
                                                    : null
                                                }

                                                <li className="list-group-item d-flex justify-content-between"><div><div>Status</div></div>
                                                    <div className={`badge bg-${activeWallet?.status == "succeeded" || activeWallet?.status == "succeed" ? "success" : "danger"}`} style={{ textTransform: "capitalize" }}>
                                                        {activeWallet?.status == "succeeded" || activeWallet?.status == "succeed" ? "Success" : activeWallet?.status}</div>
                                                </li>
                                               
                                            </ul>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div> */}
                            <form onSubmit={(e) => handleSubmitUser(e)}>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className=' mb-2'>
                                            <Form.Label><strong>First Name*</strong></Form.Label>
                                            <Form.Control type="text" name="first_name" placeholder=""
                                                onChange={handleInputchange}
                                                value={userProfil?.first_name}
                                                isInvalid={errors && userProfil?.first_name == ""}
                                            />
                                            <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className=' mb-2'>
                                            <Form.Label><strong>Last Name*</strong></Form.Label>
                                            <Form.Control type="text" name="last_name" placeholder=""
                                                onChange={handleInputchange}
                                                value={userProfil?.last_name}
                                                isInvalid={errors && userProfil?.last_name == ""}
                                            />
                                            <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-12'>
                                        <Form.Group className=' mb-2'>
                                            <Form.Label><strong>Email address*</strong></Form.Label>
                                            <Form.Control type="email" name="email" placeholder="" onChange={handleInputchange} value={userProfil?.email} isInvalid={errors && (userProfil?.email == "" || (userProfil?.email !== "" && invalidEmail))} />
                                            {errors && !invalidEmail ?
                                                <Form.Control.Feedback type="invalid">Please enter email</Form.Control.Feedback>
                                                : null
                                            }
                                            {errors && invalidEmail ?
                                                <Form.Control.Feedback type="invalid">Wrong email format</Form.Control.Feedback>
                                                : null
                                            }
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-12'>

                                        <div className="form-group">
                                            <div className='text-center mt-2'>
                                                <button className='btn btn-primary me-3' type='submit'> Confirm</button>
                                                <button type='button' className='btn btn-outline-danger  ' onClick={handleClose} > Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>






                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </main >
    )
}
