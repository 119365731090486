import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import _ from "underscore"
import { Pagination } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { energyfunction } from '../../../../Config/energyFunction';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';

export default function Session() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const param = useParams()
    const [UserSession, setUserSession] = useState([])
    const [popData, setPopData] = useState({})
    const [show, setShow] = useState(false);
    const [modalType, setmodalType] = useState("")
    const [refundData, setrefundData] = useState({})
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
    })
    const [count, setCount] = useState(1)

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    const getUserSession = (id, param) => {
        Api.GetApi(`${endPoints.USER_SESSION}/${id}/${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                let arr = []

                res.data.data.length > 0 && res.data.data[0].result.length > 0 && res.data.data[0].result.map((opt) => {
                    var a = moment(opt?.charging_activity[0]?.startTime);
                    
                
                        var b = moment(opt?.charging_activity[0]?.endTime);
                        var duration = moment.duration(b.diff(a));
                        duration.hours()
                        duration.minutes()
                        duration.seconds()
                        let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                            ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""}      
                            ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`
    
                        arr.push({ ...opt, duration: duration1 })
                   
                })
                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }


                setUserSession(arr)

                // setUser(arr)

                setloading(false)
            }
        })
    }

    const handleClose = () => {
        setShow(false);
        setrefundData({})
    }
    const handleShow = () => {
        setShow(true)
    }
 
    const sendDataToPop = (data) => {  
        if (data.status === "Started" || data.session_status === "Cancelled" || data?.paid_amount == 0 || data.cost.amount == 0 ) { }
        else { 
            setmodalType("receipt")
            setPopData(data)
            handleShow()
        }
    }


    const maxVisiblePages = 5;
    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                    number <= params.page + Math.floor(maxVisiblePages / 2))
            ) {
                pageItems.push(
                    <Pagination.Item
                        key={number}
                        active={number == params.page}
                        onClick={() => pageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                (number === totalPages - 1 &&
                    params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
            ) {
                // Display ellipsis for the second and second-to-last pages
                pageItems.push(<Pagination.Ellipsis key={number} />);
            }
        }

        return pageItems;
    };
    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        }else{            
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }
    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])

    const prevPage = () => {
        setloading(true)
        setParams({ ...params, page: params.page - 1 })
        setparam("page", params.page - 1)
    }
    const nextPage = () => {
        setloading(true)
        setParams({ ...params, page: params.page + 1 })
        setparam("page", params.page + 1)
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        }
    }

    const refundClick = (data) => {
        setShow(true)
        setmodalType("refund")
        setrefundData(data)
    }
    const confirmClick = () => {
        if (refundData?._id) {
            Api.PutApi(endPoints.USER_SESSION_REFUND, { sessionId: refundData?._id }).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert(res.data.data, "success")
                    handleClose()
                    getUserSession(param.id, location.search)
                }
            })
        } else {
            Api.Alert("something went wrong", "error")
        }
    }

    useEffect(() => {
        // setparam("page", params.page)
        getUserSession(param.id, location.search)
    }, [location.search])
    // console.log("UserSession", UserSession)
    console.log("popData", popData)
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Session History </h1>
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className='mb-3'>
                                <Link onClick={(e) => { e.preventDefault(); navigate(-1) }} className="card-title"> <Icon.ArrowLeft /> <span className='ms-2 userName'>  {UserSession.length > 0 && UserSession[0]?.user?.first_name} {UserSession.length > 0 && UserSession[0].user?.last_name || "Back"} </span></Link>
                            </div>

                            {UserSession && UserSession.length > 0 ?
                                <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th className='sorting' scope="col">Session Id</th>
                                                    <th className='sorting' scope="col">Station</th>
                                                    <th className='sorting' scope="col">Partner</th>
                                                    <th scope="col">Start Time</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Consumption(kWh)</th>
                                                    <th scope="col">Cost<small>(MYR)</small> </th>
                                                    <th scope="col">Payment Status</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {UserSession.length > 0 && UserSession.map((data, index) => {
                                                    let updInd = index + 1
                                                    // console.log("data", data)
                                                    return (
                                                        <tr className={data.status === "Started" || data.session_status === "Cancelled" || data?.paid_amount == 0 || data.cost.amount == 0  ? "":'pointer'} key={index}>
                                                            <td onClick={() => sendDataToPop(data)}>{(params.page - 1) * 20 + updInd}</td>
                                                            <td onClick={() => sendDataToPop(data)}>{data?._id}</td>
                                                            <td onClick={() => sendDataToPop(data)}>{data?.chargingstaion?.endpoint}</td>
                                                            <td onClick={() => sendDataToPop(data)}>{data?.assigncharger?.partner?.name || "--"}</td>
                                                            <td onClick={() => sendDataToPop(data)}>{formatDate(data?.metrics?.chargingStart)}</td>
                                                            <td onClick={() => sendDataToPop(data)}>{data?.duration || "--"}</td>
                                                            <td onClick={() => sendDataToPop(data)}>{energyfunction( data?.metrics?.energyConsumed)}</td>
                                                            {/* <td>RM {data?.cost?.amount}</td> */}
                                                            <td onClick={() => sendDataToPop(data)}>
                                                                {data?.paid_amount ? (
                                                                    <CurrencyFormat
                                                                        value={parseFloat(data?.paid_amount).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                                ) : data?.cost?.amount ? (
                                                                    <CurrencyFormat
                                                                        value={parseFloat(data?.cost?.amount).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} prefix={'RM '} />) : (
                                                                            <CurrencyFormat
                                                                        value={parseFloat(0).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} prefix={'RM '} />
                                                                        )
                                                                }



                                                            </td>
                                                            <td onClick={() => sendDataToPop(data)}><span className={data?.session_status == "Paid" ? "bg-success badge" : data?.session_status == "Refunded" ? "bg-secondary badge" : "bg-danger badge"}>{data?.session_status}</span></td>
                                                           
                                                            <td onClick={() => sendDataToPop(data)}  >{data?.payment_method}</td>
                                                            <td onClick={() => sendDataToPop(data)} style={{ color: data?.status == "Ended" ? "#ff0000" : "" }}>{data?.status}</td>
                                                            <td>
                                                                {data?.session_status !== "Refunded" && data?.session_status !== "Cancelled" && data?.session_status !== "Unpaid" ?
                                                                    <button className='btn btn-danger btn-sm' onClick={() => refundClick(data)}>Refund</button>
                                                                    : "--"
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* <Pagination>
                                        <Pagination.First onClick={prevPage} disabled={params.page == 1 ? true : false} />
                                        {count.length > 0 && count.map((number) => {
                                            return (

                                                <Pagination.Item onClick={() => pageChange(number)} key={number} active={number == params.page}>
                                                    {number}
                                                </Pagination.Item>

                                            )
                                        })}
                                        <Pagination.Last onClick={nextPage} disabled={params.page == count.length ? true : false} />
                                    </Pagination> */}

                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />

                                    </div>


                                </> :
                                <div className='text-center py-3'>
                                    <h3>No session history found</h3>
                                </div>
                            }
                        </div>
                    </div>



                    <Modal show={show} onHide={handleClose} centered>
                        {modalType == "receipt" &&
                            <Modal.Header closeButton>
                                <Modal.Title>Receipt</Modal.Title>
                            </Modal.Header>
                        }
                        <Modal.Body>
                            {modalType == "receipt" && <>
                                {!_.isEmpty(popData) &&
                                    <>
                                        <div className="alert alert-warning  fade show d-flex justify-content-between" role="alert">
                                            <span> {moment(popData?.metrics?.chargingStart, "YYYY-MM-DD hh:mm:ss A").format("lll")}</span>
                                            <span className="badge bg-warning text-dark">
                                                <CurrencyFormat value={parseFloat(popData?.paid_amount || popData?.cost?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></span>
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            {/* <li className="list-group-item d-flex justify-content-between"><div>ID</div> <div>{popData?.ev_session_id}</div></li> */}
                                            <li className="list-group-item d-flex justify-content-between"><div>Status</div> <div style={{ color: popData?.status == "Ended" ? "#ff0000" : "" }}>{popData?.status}</div></li>
                                            <li className="list-group-item d-flex justify-content-between"><div><strong>Charging Summary</strong></div> <div><strong>Amount</strong></div></li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <div>
                                                    <div> {popData?.duration}</div>
                                                    <div>{energyfunction(popData?.metrics?.energyConsumed)} </div>
                                                </div>
                                                <div><CurrencyFormat value={parseFloat(popData?.cost?.amount - 1).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></div></li>

                                            {popData?.membership_benefit?.value != 0 && popData?.membership_benefit ?
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <div><strong> {popData?.membership_benefit?.discount_type === "percentage" ?
                                                        <>{popData?.membership_benefit?.value}% Discount</> :
                                                        <CurrencyFormat value={parseFloat(popData?.membership_benefit?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</strong></div>
                                                    <div><strong style={{ color: "red" }}> -<CurrencyFormat value={parseFloat(popData?.membership_benefit?.discount_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></strong></div></li> : null
                                            }

                                            <li className="list-group-item d-flex justify-content-between"> <div>Confirmation Fee</div> <div>RM 1.00</div></li>
                                            <li className="list-group-item d-flex justify-content-between"> <div><strong>Total</strong></div> <div><strong><CurrencyFormat value={parseFloat(popData?.paid_amount || popData?.cost?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></strong></div></li>
                                            <li className="list-group-item d-flex justify-content-between"><div>Start Time</div> <div>{moment(popData?.metrics?.chargingStart, "YYYY-MM-DD hh:mm:ss A").format("lll")}</div></li>
                                            <li className="list-group-item d-flex justify-content-between"><div>Stop Time</div> <div>{moment(popData?.metrics?.chargingStop, "YYYY-MM-DD hh:mm:ss A").format("lll")}</div></li>
                                            <li className="list-group-item d-flex justify-content-between"><div>Charging Bay</div> <div>{popData?.chargingstaion?.connector.type} Connector{popData?.chargingstaion?.connector.connectorId} {popData?.chargingstaion?.protocol}  </div></li>
                                            <li className="list-group-item d-flex justify-content-between"><div>Session ID</div> <div>{popData?._id}</div></li>
                                            {popData?.transactions.length > 0 && (popData?.session_status == "Paid" || popData?.session_status == "Refunded") ?
                                                <>
                                                    {popData?.transactions[0]?.status !== "failed" ?
                                                        <li className="list-group-item d-flex justify-content-between">
                                                            <div>Payment Type {popData?.transactions[0]?.payment_method_details?.type == "card" ? <span className='text-capitalize'>({popData?.transactions[0]?.payment_method_details?.type})</span> : ""}
                                                            </div>
                                                            {popData?.transactions[0]?.payment_method_details?.type === "card" ?
                                                                <div> <strong className='text-capitalize'>{popData?.transactions[0]?.payment_method_details?.card?.brand}</strong> ending in  <strong>{popData?.transactions[0]?.payment_method_details?.card?.last4}</strong></div>
                                                                :
                                                                <span className='text-capitalize'>{popData?.transactions[0]?.payment_method_details?.type ?? ""}</span>
                                                            }
                                                        </li>
                                                        :
                                                        <li className="list-group-item d-flex justify-content-between"><div>Payment</div> <div className='text-capitalize' style={{ color: "red" }}>{popData?.transactions[0]?.status}</div></li>
                                                    }
                                                    <li className="list-group-item d-flex justify-content-between"><div>Payment ID</div> <div>  {popData?.transactions[0]?.transaction_id} </div></li>
                                                </>
                                                : null
                                            }

                                        </ul>
                                    </>
                                }
                            </>
                            }

                            {modalType == "refund" && <>
                                <div className='text-center mb-4'>
                                    <h4> Are you sure?</h4>
                                    <h5>You want to refund this transaction.</h5>
                                </div>
                                <div className='text-center mt-2'>
                                    <button className='btn btn-danger btn-sm me-3' onClick={confirmClick}> Confirm</button>
                                    <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                                </div>
                            </>}
                        </Modal.Body>

                    </Modal>

                </main>}
        </>
    )
}
